import actions from './actions';

export const initState = {
  // State for Risk Analysis Question
  markersMounted: false,
  showAnalysis: false,
  showRatingPopup: false,
  step: 0,
};

export default function riskAnalysisReducer(
  state = initState,
  { type }
) {
  switch (type) {
    case actions.TOGGLE_ANALYSIS:
      return ({
        ...state,
        showAnalysis: !state.showAnalysis,
      });
    case actions.TOGGLE_RATING_POPUP:
      return ({
        ...state,
        showRatingPopup: !state.showRatingPopup,
      });
    default:
      return state;
  }
}
