import { all, put, select, takeLatest } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import makeToast, { toastType } from './QuestionToast';
import notification from '../../components/notification';
import Axios from '../../utils/axios';
import actions from './actions';
import firebase, { auth } from 'firebase';
import { getIdTokenResult } from 'firebase';

export function* getQuestions() {
  try {
    const questionsResponse = yield Axios.get('/v1/resources/questions', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const currentUser = localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token')) : '';

    const { status, data } = questionsResponse;
    if (status === 200 && data) {
      const items = [];
      data.questions.map((question) => {
        if (question.createdBy) {
          const userRef = Object.values(question.createdBy)[1].segments[1];
          items.push({
            ...question,
            editable: userRef && userRef == currentUser.googleUserId,
            // editable: true,
          });
        } else {
          items.push({ ...question });
        }
      });
      yield put({
        type: actions.QUESTION_GET_QUESTION_SUCCESS,
        questions: items,
      });
    } else {
      yield put({
        type: actions.QUESTION_GET_QUESTION_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: actions.QUESTION_GET_QUESTION_FAILED,
    });
  }
}

function* saveQuestion(params) {
  const { question, isNewQuestion, history } = params;
  const { id, pin } = question;
  const currentUser = jwtDecode(localStorage.getItem('token')).googleUserId;

  try {
    let resQuestion = null;
    let res = null;
    let instantQuestions = null;
    let instantQuestionId = null;
    if (isNewQuestion) {
      resQuestion = yield Axios.post('/v1/resources/questions/', question, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      res = yield Axios.post('/v1/resources/instant_questions/', {question: `${resQuestion.data.id}`, pin: `${pin}`, status: 'inactive', user: `${currentUser}`}, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else {
      instantQuestions = yield select((state) => {
        return state.InstantQuestion.Teacher.instantQuestions
      });
      instantQuestionId = instantQuestions.find(x => x.question === `questions/${id}`)?.id;
      resQuestion = yield Axios.put(`/v1/resources/questions/${id}`, question, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      res = yield Axios.put(`/v1/resources/instant_questions/${instantQuestionId}`, {question: `${resQuestion.data.id}`, pin: `${pin}`, status: 'inactive', user: `${currentUser}`}, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    }

    // data.question -> data -- git branch: feature/shaun/setmarker_question
    const { status, data } = resQuestion;
    if ((status === 200 || status === 201) && data) {
      yield put({
        type: actions.QUESTION_SAVE_QUESTION_SUCCESS,
        question: {...data, editable: true},
      });
      yield history.push(`/pages/instant-questions/${data?.id}`);
    } else {
      makeToast(toastType.SAVE_QUESTION_FAILED);
      yield put({
        type: actions.QUESTION_SAVE_QUESTION_FAILED,
      });
    }
  } catch (err) {
    console.log('sagaError',err);
    makeToast(toastType.SAVE_QUESTION_FAILED);
    yield put({
      type: actions.QUESTION_SAVE_QUESTION_FAILED,
    });
  }
}

function* deleteQuestion(params) {
  const { questionId, id } = params;
  //eg. questionId: {question_1024}, id: {344b2b}
  try {
    let res = null;
    res = yield Axios.delete(`/v1/resources/questions/${questionId}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    res = yield Axios.delete(`/v1/resources/instant_questions/${id}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const { status } = res;
    if (status === 204) {
      yield put({
        type: actions.QUESTION_DELETE_QUESTION_SUCCESS,
        questionId,
      });
      notification(
        'success',
        'Question Deleted',
        `Question with ID - ${questionId} has been deleted`
      );
    }
  } catch (err) {
    yield put({
      type: actions.QUESTION_DELETE_QUESTION_FAILED,
    });
  }
}

// function* selectQuestion() {
//   yield put({

//   })
// }

export default function* rootSaga() {
  yield all([
    takeLatest(actions.QUESTION_GET_QUESTIONS, getQuestions),
    takeLatest(actions.QUESTION_SAVE_QUESTION, saveQuestion),
    takeLatest(actions.QUESTION_DELETE_QUESTION, deleteQuestion),
    // takeLatest(actions.QUESTION_SELECT_CURRENT_QUESTION, selectQuestion),
  ]);
}
