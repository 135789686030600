import actions from './actions';
import riskAnalysisReducer, {
  initState as riskAnalysisInitState,
} from './RiskAnalysis/reducer';
import setMarkerReducer, {
  initState as setMarkerInitState,
} from './SetMarker/reducer';

const initState = {
  err: '',
  isFetching: false,
  isFetchingQuestion: false,
  isResetting: false,
  isLoadingQuestion: false,
  isStartingQuestion: false,
  isActivating: false,
  isFinishing: false,
  answerToggled: false,

  totalQuestionNumber: 0,
  // quiz session info
  sessionId: '',
  name: '',
  status: '',
  shareCode: '',
  progress: {
    currentQuestionNumber: -1,
    load: false,
    start: false,
    startTimestamp: '',
    step: 0,
  },

  // realtime update
  studentEntered: 0,
  studentLoaded: 0,
  studentAnswers: {},

  // current question
  question: {
    id: '',
    course: '',
    difficulty: 0,
    duration: 0,
    subject: '',
    type: '',
    questionContent: {
      choicesSet: {},
      correctAnswers: [],
    },
  },

  // additional attribute for set marker question
  setMarkerQuestion: setMarkerInitState,
  riskAnalysisQuestion: riskAnalysisInitState,
};

export default function quizSessionReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    /**
     * GET QUIZ SESSION
     */
    case actions.GET_QUIZ_SESSION:
      return {
        ...state,
        isFetching: true,
      };
    case actions.GET_QUIZ_SESSION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        ...action.quizSession,
        sessionId: action.quizSession.shareCode,
      };
    case actions.GET_QUIZ_SESSION_FAILED:
      return {
        ...state,
        err: action.err,
        isFetching: false,
      };

    /**
     * GET QUIZ SESSION QUESTION
     */
    case actions.GET_QUIZ_SESSION_QUESTION:
      return {
        ...state,
        isFetchingQuestion: true,
      };
    case actions.GET_QUIZ_SESSION_QUESTION_SUCCESS: {
      return {
        ...state,
        isFetchingQuestion: false,
        question: action.question,
        answerToggled: false,
      };
    }
    case actions.GET_QUIZ_SESSION_QUESTION_FAILED:
      return {
        ...state,
        err: action.err,
        isFetchingQuestion: false,
      };

    /**
     * SYNC WITH FIRESTORE
     */
    case actions.SYNC_STUDENT_ENTERED: {
      if (action.studentEntered instanceof Error) {
        return state;
      }
      return {
        ...state,
        studentEntered: action.studentEntered,
      };
    }

    case actions.SYNC_STUDENT_LOADED: {
      if (action.studentLoaded instanceof Error) {
        return state;
      }
      return {
        ...state,
        studentLoaded: action.studentLoaded,
      };
    }

    case actions.SYNC_STUDENT_ANSWERS: {
      // Return the answer as object for other question types
      if (action.studentAnswers instanceof Error) {
        return state;
      }
      if (state.question.questionType !== 'multiple') {
        return {
          ...state,
          studentAnswers: action.studentAnswers,
        };
      }
      // Convert the answer to lists in other cases
      const currentStudentAnswers = [];
      const { studentAnswers } = action;
      Object.keys(studentAnswers).forEach((key, i) => {
        currentStudentAnswers.push(studentAnswers[i]);
      });
      return {
        ...state,
        studentAnswers: currentStudentAnswers,
      };
    }
    case actions.SYNC_QUIZ_SESSION: {
      if (action.quizSession instanceof Error) {
        return state;
      }
      return {
        ...state,
        ...action.quizSession,
        sessionId: action.quizSession.shareCode,
      };
    }

    /**
     * TOGGLE ANSWER
     */
    case actions.TOGGLE_ANSWER:
      return {
        ...state,
        answerToggled: !state.answerToggled,
      };

    case actions.CLEAR_QUIZ_SESSION:
      return initState;
    /**
     * LOAD QUESTION
     */
    case actions.LOAD_QUESTION:
      return {
        ...state,
        isLoadingQuestion: true,
      };
    case actions.LOAD_QUESTION_SUCCESS:
      return {
        ...state,
        isLoadingQuestion: false,
      };
    case actions.LOAD_QUESTION_FAILED:
      return {
        ...state,
        isLoadingQuestion: false,
      };

    /**
     * START QUESTION
     */
    case actions.START_QUESTION:
      return {
        ...state,
        progress: {
          ...state.progress,
          startTimestamp: '',
        },
        isStartingQuestion: true,
      };
    case actions.START_QUESTION_SUCCESS:
      return {
        ...state,
        isStartingQuestion: false,
      };
    case actions.START_QUESTION_FAILED:
      return {
        ...state,
        isStartingQuestion: false,
      };

    /**
     * ACTIVATE QUIZ SESSION
     */
    case actions.ACTIVATE_QUIZ_SESSION:
      return {
        ...state,
        isActivating: true,
      };
    case actions.ACTIVATE_QUIZ_SESSION_SUCCESS:
      return {
        ...state,
        sessionId: action.sessionId,
        isActivating: false,
      };
    case actions.ACTIVATE_QUIZ_SESSION_FAILED:
      return {
        ...state,
        isActivating: false,
      };

    case actions.CHANGE_STEP_SUCCESS:
      return {
        ...state,
        progress: { ...state.progress, step: action.step },
      };
    case actions.FINISH_QUIZ_SESSION:
      return {
        ...state,
        isFinishing: true,
      };
    case actions.FINISH_QUIZ_SESSION_SUCCESS:
      return {
        ...state,
        isFinishing: false,
        status: 'finish',
      };
    case actions.FINISH_QUIZ_SESSION_FAILED:
      return {
        ...state,
        isFinishing: false,
      };

    case actions.RESET_QUIZ_SESSION:
      return {
        ...state,
        isResetting: true,
      };

    case actions.RESET_QUIZ_SESSION_SUCCESS:
      return {
        ...state,
        isResetting: false,
      };

    case actions.RESET_QUIZ_SESSION_FAILED:
      return {
        ...state,
        isResetting: false,
      };

    case actions.RESET_SINGLE_QUESTION:
      return {
        ...state,
        isResetting: true,
      };

    case actions.RESET_SINGLE_QUESTION_SUCCESS:
      return {
        ...state,
        isResetting: false,
        answerToggled: false
      };

    case actions.RESET_SINGLE_QUESTION_FAILED:
      return {
        ...state,
        isResetting: false,
      };

    // Set marker question actions
    case actions.TOGGLE_HEATMAP:
    case actions.TOGGLE_CORRECT_ANSWERS:
    case actions.PANO_LOADED:
    case actions.REFRESH_COMMENT:
    case actions.SET_USER_TYPE:
    case actions.TEACHER_ADD_ANSWER:
    case actions.STUDENT_ADD_ANSWER:
    case actions.SUBMIT_ANSWERS:
      return {
        ...state,
        setMarkerQuestion: setMarkerReducer(state.setMarkerQuestion, { type, ...action }),
      };
    // Risk analysis question actions
    case actions.TOGGLE_ANALYSIS:
    case actions.SYNC_STUDENT_ANSWERS_RISK_ANALYSIS:
      return {
        ...state,
        riskAnalysisQuestion: riskAnalysisReducer(
          state.riskAnalysisQuestion,
          { type, ...action }
        ),
      };
    default:
      return state;
  }
}
