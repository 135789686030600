import actions from './actions';

export default function studentSessionReducer(
  state = {
    nickname: '',
    valid: '',
    overlay: true,
    overlayMessage: '',
    registered: false,
    showQuestion: false,
    answeredQuestion: false,
    answer: null,
    sessionId: '',
    isFetching: false,
    isError: false,
    isActive: false,
    isJoined: false,
    // TODO: Refactoring
    isFetchingQuestion: false,
    isQuestionTimeout: false,
    err: '',
    // quiz session info
    quizSession: {
      name: '',
      status: '',
      shareCode: '',
      progress: {
        currentQuestionNumber: 0,
        load: false,
        start: false,
        startTimestamp: '',
      },
    },

    // current question
    question: {
      id: '',
      course: '',
      difficulty: 0,
      duration: 0,
      subject: '',
      type: '',
      questionContent: {
        choicesSet: {},
        correctAnswers: [],
      },
    },
  },
  { type, ...action }
) {
  switch (type) {
    case actions.STUDENT_GET_QUIZ_SESSION:
      return {
        ...state,
        isFetching: true,
      };
    case actions.STUDENT_GET_QUIZ_SESSION_SUCCESS:
      return {
        ...state,
        quizSession: action.quizSession,
        isFetching: false,
      };
    case actions.STUDENT_GET_QUIZ_SESSION_FAILED:
      return {
        ...state,
        err: action.err,
        valid: '',
        isFetching: false,
      };
    case actions.STUDENT_CHANGE_NICKNAME:
      return {
        ...state,
        nickname: action.nickname,
      };
    case actions.STUDENT_TOGGLE_OVERLAY:
      return {
        ...state,
        overlay: !state.overlay,
      };
    case actions.STUDENT_NICKNAME_VALIDATING:
      return {
        ...state,
        valid: 'validating',
      };
    case actions.STUDENT_NICKNAME_VALID:
      return {
        ...state,
        valid: 'success',
      };
    case actions.STUDENT_NICKNAME_INVALID:
      return {
        ...state,
        valid: 'error',
      };
    case actions.STUDENT_NICKNAME_EMPTY:
      return {
        ...state,
        valid: 'empty',
      };
    case actions.STUDENT_NICKNAME_ERROR:
      return {
        ...state,
        valid: 'inactive',
      };
    case actions.STUDENT_NICKNAME_REGISTER_SUCCESS:
      return {
        ...state,
        registered: true,
      };
    case actions.STUDENT_NICKNAME_REGISTER_FAIL:
      return {
        ...state,
        registered: false,
      };
    case actions.STUDENT_SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.quizSessionId,
      };
    case actions.STUDENT_SYNC_QUIZ_SESSION:
      return {
        ...state,
        quizSession: action.quizSession,
      };
    case actions.STUDENT_GET_QUIZ_SESSION_QUESTION_SUCCESS:
      return {
        ...state,
        question: action.question,
        answeredQuestion: false,
      };
    case actions.STUDENT_CHOOSE_ANSWER:
      return {
        ...state,
        answer: action.answer,
      };
    case action.STUDENT_SUBMIT_ANSWER:
      return {
        ...state,
        answeredQuestion: true,
      };
    case actions.STUDENT_SUBMIT_ANSWER_POLL:
      return {
        ...state,
        answeredQuestion: true,
      };
    case actions.STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE:
      return {
        ...state,
        answeredQuestion: true,
      };
    case actions.STUDENT_SUBMIT_ANSWER_SET_MARKER:
      return {
        ...state,
        answeredQuestion: true,
        answer: action.answer,
      };
    case actions.STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS:
      return {
        ...state,
        answeredQuestion: true,
      };
    case actions.STUDENT_RESET_QUESTION_TIMER:
      return {
        ...state,
        isQuestionTimeout: false,
      };
    case actions.STUDENT_SET_QUESTION_TIMEOUT:
      return {
        ...state,
        isQuestionTimeout: true,
      };
    // TODO: modified later
    case actions.STUDENT_QUIZ_SESSION_FINISH:
      return {
        ...state,
        quizSession: {
          ...state.quizSession,
          status: 'finish',
        },
      };
    case actions.STUDENT_RESET_ANSWER:
      return {
        ...state,
        answer: null,
        answeredQuestion: false,
      };
    case actions.STUDENT_CHANGE_SESSIONID:
      return {
        ...state,
        sessionId: action.sessionId,
      };
    case actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS:
      return {
        ...state,
        isFetching: true,
        isError: false,
        isActive: false,
      };
    case actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isActive: true,
      };
    case actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_FAILED:
      return {
        ...state,
        isFetching: false,
        isError: true,
        isActive: false,
      };
    case actions.STUDENT_JOIN_QUIZ_SESSION:
      return {
        ...state,
        isJoined: true,
      };
    case actions.STUDENT_ENTER_QUIZ_SESSION_EMPTY:
      return {
        ...state,
        isError: true,
        isActive: false,
      };
    default:
      return state;
  }
}
