const actions = {
  AUTH_TRADE_FOR_TOKEN: 'AUTH_TRADE_FOR_TOKEN',
  AUTH_TRADE_FOR_TOKEN_SUCCESS: 'AUTH_TRADE_FOR_TOKEN_SUCCESS',
  AUTH_TRADE_FOR_TOKEN_FAILED: 'AUTH_TRADE_FOR_TOKEN_FAILED',

  tradeForToken: (googleToken, history) => ({
    type: actions.AUTH_TRADE_FOR_TOKEN,
    googleToken,
    history,
  }),
};

export default actions;
