function waitUntilLoaded(callback, depth = 0) {
  if (depth > 5) {
    return;
  }
  if (!window.Tawk_API.hideWidget && !window.Tawk_API.showWidget) {
    setTimeout(() => waitUntilLoaded(callback, depth + 1), 1000);
  } else {
    callback();
  }
}

export const showTawkTo = () => {
  waitUntilLoaded(() => {
    if (
      window.Tawk_API !== undefined &&
      typeof window.Tawk_API.hideWidget === 'function' &&
      window.Tawk_API.isChatHidden
    ) {
      if (window.Tawk_API.isChatHidden()) {
        window.Tawk_API.showWidget();
      }
    }
  });
};

export const hideTawkTo = () => {
  waitUntilLoaded(() => {
    if (
      window.Tawk_API !== undefined &&
      typeof window.Tawk_API.hideWidget === 'function' &&
      window.Tawk_API.isChatHidden
    ) {
      if (!window.Tawk_API.isChatHidden()) {
        window.Tawk_API.hideWidget();
      }
    }
  });
};
