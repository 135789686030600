import studentActions from './Student/actions';

const actions = {
  INSTANT_QUESTION_CHECKPIN: 'INSTANT_QUESTION_CHECKPIN',
  INSTANT_QUESTION_CHECKPIN_SUCCESS: 'INSTANT_QUESTION_CHECKPIN_SUCCESS',
  INSTANT_QUESTION_CHECKPIN_FAILED: 'INSTANT_QUESTION_CHECKPIN_FAILED',

  INSTANT_STUDENT_SYNC_QUESTION: 'INSTANT_STUDENT_SYNC_QUESTION',
  INSTANT_STUDENT_SYNC_QUESTION_SUCCESS: 'INSTANT_STUDENT_SYNC_QUESTION_SUCCESS',
  INSTANT_STUDENT_SYNC_QUESTION_FAILED: 'INSTANT_STUDENT_SYNC_QUESTION_FAILED',
  INSTANT_SYNC_TEACHER_CURRENT: 'INSTANT_SYNC_TEACHER_CURRENT',
  INSTANT_SYNC_TEACHER_CURRENT_SUCCESS: 'INSTANT_SYNC_TEACHER_CURRENT_SUCCESS',
  INSTANT_SYNC_TEACHER_CURRENT_FAILED: 'INSTANT_SYNC_TEACHER_CURRENT_FAILED',

  INSTANT_SET_TEACHER_UUID: 'INSTANT_SET_TEACHER_UUID',

  INSTANT_GET_QUESTION_SUCCESS: 'INSTANT_GET_QUESTION_SUCCESS',
  INSTANT_GET_QUESTION_FAILED: 'INSTANT_GET_QUESTION_FAILED',

  INSTANT_CLEAR_QUESTION: 'INSTANT_CLEAR_QUESTION',
  INSTANT_CLEAR_QUESTION_SUCCESS: 'INSTANT_CLEAR_QUESTION_SUCCESS',

  INSTANT_SET_QUESTION_CORRECT_ANSWER: 'INSTANT_SET_QUESTION_CORRECT_ANSWER',
  INSTANT_SET_QUESTION_CORRECT_ANSWER_SUCCESS: 'INSTANT_SET_QUESTION_CORRECT_ANSWER_SUCCESS',
  INSTANT_SET_QUESTION_CORRECT_ANSWER_FAILED: 'INSTANT_SET_QUESTION_CORRECT_ANSWER_FAILED',

  syncInstantQuestion: questionId => ({
    type: actions.INSTANT_STUDENT_SYNC_QUESTION,
    questionId,
  }),

  syncTeacherCurrent: ({ teacherName }) => ({
    type: actions.INSTANT_SYNC_TEACHER_CURRENT,
    teacherName
  }),

  clearQuestion: () => ({
    type: actions.INSTANT_CLEAR_QUESTION,
  }),

  ...studentActions
};

export default actions;
