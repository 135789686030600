import Axios from 'axios';
import { toast } from 'react-toastify';
import { host, server } from '../config';

export const serverUrl = server.production;
export const hostUrl = host.production; // betaProduction; // local
console.log('hostUrl', hostUrl);

Axios.defaults.baseURL = serverUrl;

Axios.interceptors.request.use(config => {
  const { method, url /** headers */ } = config;

  console.log(
    `${method.toUpperCase()} ${url}`,
    // data || ''
    // headers.authorization || ''
    `url`,url, `method`,method
  );

  return config;
});

Axios.interceptors.response.use(
  response => {
    const { status, data } = response;
    console.log(`response`,status, data);

    return response;
  },
  error => {
    const { status, data } = error.response;

    console.log(`error occurs`,status, data);

    if (status === 403) {
      toast.error('Token invalid or expired', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      localStorage.clear('token');
      localStorage.clear('instantToken');
      // TODO receive redirect url from response
      window.location.href = `${hostUrl}/#/instants`;
      // window.location.href = `${serverUrl}/#/instants`;
    } else if (status === 401) {
      toast.error('Missing access token', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      localStorage.clear('token');
      localStorage.clear('instantToken');
      window.location.href = `${hostUrl}/#/instants`;
      // window.location.href = `${serverUrl}/#/instants`;
    }

    return Promise.reject(error);
  }
);
const getToken = () => `Bearer ${localStorage.getItem('token')}`;

/**
 * Quiz Session APIs
 */
export const QuizSessionApi = {
  activateQuizSession: sessionId => Axios.post(
    `/v1/resources/quiz_sessions/${sessionId}/control/activate/`,
    {},
    {
      headers: {
        authorization: getToken(),
      },
    }
  ),
  getQuizSessionQuestion: (sessionId, questionNumber) => Axios.get(
    `/v1/resources/quiz_sessions/${sessionId}/questions/${questionNumber}`,
    {
      headers: {
        authorization: getToken(),
      },
    }
  ),
  loadQuestion: (sessionId, questionNumber) => Axios.post(
    `/v1/resources/quiz_sessions/${sessionId}/control/load/${questionNumber}`,
    {},
    {
      headers: {
        authorization: getToken(),
      },
    }
  ),
  startQuestion: (sessionId, questionNumber) => Axios.post(
    `/v1/resources/quiz_sessions/${sessionId}/control/start/${questionNumber}`,
    {},
    {
      headers: {
        authorization: getToken(),
      },
    }
  ),
  finishQuizSession: sessionId => Axios.post(
    `/v1/resources/quiz_sessions/${sessionId}/control/finish/`,
    {},
    {
      headers: {
        authorization: getToken(),
      },
    }
  ),
  resetQuizSession: sessionId => Axios.post(
    `/v1/resources/quiz_sessions/${sessionId}/control/reset/`,
    {},
    {
      headers: {
        authorization: getToken(),
      },
    }
  ),
  resetSingleQuestion: (sessionId, currentQuestionNumber) => Axios.post(
    `/v1/resources/quiz_sessions/${sessionId}/questions/${currentQuestionNumber}/reset`,
    {},
    {
      headers: {
        authorization: getToken(),
      },
    }
  ),
  changeStep: (sessionId, stepNumber) => Axios.post(
    `/v1/resources/quiz_sessions/${sessionId}/control/step/${stepNumber}`,
    {},
    {
      headers: {
        authorization: getToken(),
      },
    }
  ),
};
export default Axios;
