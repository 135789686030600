const actions = {
  QUIZ_GET_QUIZZES: 'QUIZ_GET_QUIZZES',
  QUIZ_GET_QUIZZES_SUCCESS: 'QUIZ_GET_QUIZZES_SUCCESS',
  QUIZ_GET_QUIZZES_FAILED: 'QUIZ_GET_QUIZZES_FAILED',

  QUIZ_GET_QUIZ_QUESTIONS: 'QUIZ_GET_QUIZ_QUESTIONS',
  QUIZ_GET_QUIZ_QUESTIONS_SUCCESS: 'QUIZ_GET_QUIZ_QUESTIONS_SUCCESS',
  QUIZ_GET_QUIZ_QUESTIONS_FAILED: 'QUIZ_GET_QUIZ_QUESTIONS_FAILED',

  QUIZ_EDIT_QUIZ_QUESTIONS: 'QUIZ_EDIT_QUIZ_QUESTIONS',
  QUIZ_EDIT_QUIZ_QUESTIONS_SUCCESS: 'QUIZ_EDIT_QUIZ_QUESTIONS_SUCCESS',
  QUIZ_EDIT_QUIZ_QUESTIONS_FAILED: 'QUIZ_EDIT_QUIZ_QUESTIONS_FAILED',

  QUIZ_UPDATE_QUIZ_QUESTIONS: 'QUIZ_UPDATE_QUIZ_QUESTIONS',
  QUIZ_RESET_QUIZ_QUESTIONS: 'QUIZ_RESET_QUIZ_QUESTIONS',

  QUIZ_SELECT_CURRENT_QUIZ: 'QUIZ_SELECT_CURRENT_QUIZ',

  QUIZ_INIT_QUIZ: 'QUIZ_INIT_QUIZ',

  QUIZ_TOGGLE_VIEW: 'QUIZ_TOGGLE_VIEW',

  QUIZ_EDIT_QUIZ: 'QUIZ_EDIT_QUIZ',

  QUIZ_SAVE_QUIZ: 'QUIZ_SAVE_QUIZ',
  QUIZ_SAVE_QUIZ_SUCCESS: 'QUIZ_SAVE_QUIZ_SUCCESS',
  QUIZ_SAVE_QUIZ_FAILED: 'QUIZ_SAVE_QUIZ_FAILED',

  QUIZ_CANCEL_CREATE_QUIZ: 'QUIZ_CANCEL_CREATE_QUIZ',

  QUIZ_DELETE_QUIZ: 'QUIZ_DELETE_QUIZ',
  QUIZ_DELETE_QUIZ_SUCCESS: 'QUIZ_DELETE_QUIZ_SUCCESS',
  QUIZ_DELETE_QUIZ_FAILED: 'QUIZ_DELETE_QUIZ_FAILED',

  QUIZ_CLEAR_CURRENT_QUIZ: 'QUIZ_CLEAR_CURRENT_QUIZ',

  QUIZ_REINIT_QUIZ: 'QUIZ_REINIT_QUIZ',
  QUIZ_REINIT_QUIZ_SUCCESS: 'QUIZ_REINIT_QUIZ_SUCCESS',
  QUIZ_REINIT_QUIZ_FAILED: 'QUIZ_REINIT_QUIZ_FAILED',

  getQuizzes: () => ({
    type: actions.QUIZ_GET_QUIZZES,
  }),

  getQuizQuestions: ({ quizId }) => ({
    type: actions.QUIZ_GET_QUIZ_QUESTIONS,
    quizId,
  }),

  updateQuizQuestions: ({ quizQuestions }) => ({
    type: actions.QUIZ_UPDATE_QUIZ_QUESTIONS,
    quizQuestions,
  }),

  resetQuizQuestions: () => ({
    type: actions.QUIZ_RESET_QUIZ_QUESTIONS,
  }),

  editQuizQuestions: ({ quizId, quizQuestions }) => ({
    type: actions.QUIZ_EDIT_QUIZ_QUESTIONS,
    quizId,
    quizQuestions,
  }),

  selectCurrentQuiz: quizId => ({
    type: actions.QUIZ_SELECT_CURRENT_QUIZ,
    quizId,
  }),

  initQuiz: () => ({
    type: actions.QUIZ_INIT_QUIZ,
  }),

  toggleView: view => ({ type: actions.QUIZ_TOGGLE_VIEW, view }),

  editQuiz: quiz => ({
    type: actions.QUIZ_EDIT_QUIZ,
    quiz,
  }),

  saveQuiz: isNewQuiz => (dispatch, getState) => {
    const quiz = getState().Quiz.editableQuiz;
    dispatch({
      type: actions.QUIZ_SAVE_QUIZ,
      quiz,
      isNewQuiz,
    });
  },

  cancelCreateQuiz: () => ({
    type: actions.QUIZ_CANCEL_CREATE_QUIZ,
  }),

  deleteQuiz: ({ quizId }) => ({
    type: actions.QUIZ_DELETE_QUIZ,
    quizId,
  }),

  reinitQuiz: ({ quizId }) => ({
    type: actions.QUIZ_REINIT_QUIZ,
    quizId,
  }),

  clearCurrentQuiz: () => ({
    type: actions.QUIZ_CLEAR_CURRENT_QUIZ,
  }),
};

export default actions;
