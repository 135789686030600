import { all } from 'redux-saga/effects';
import authSaga from './Auth/saga';
import quizSessionSaga from './QuizSession/saga';
import subjectSaga from './Subject/saga';
import questionSaga from './Question/saga';
import instantQuestionSaga from './InstantQuestion/saga';
import instantQuestionStudentSaga from './InstantQuestionStudent/saga';
import quizSaga from './Quiz/saga';
import student from './Student/saga';

export default function* rootSaga(/** getState */) {
  yield all([
    subjectSaga(),
    quizSessionSaga(),
    questionSaga(),
    instantQuestionSaga(),
    instantQuestionStudentSaga(),
    student(),
    authSaga(),
    quizSaga(),
  ]);
}
