import actions from './actions';

export const teacherInitState = {
  // Used in instant questions list in teacher dashboard
  instantQuestions: [],
  isFetchingInstantQuestions: false,

  authenticated: false,
  isAuthenticating: false,
  authenticationError: null,
  answerToggled: false,
  isRestarting: false,
  isActivating: false,
  studentAnswers: {},
  showAnalysis: false,
};

export default (state = teacherInitState, { type, ...action }) => {
  switch (type) {
    case actions.INSTANT_SYNC_STUDENT_ANSWERS_SUCCESS:
      return {
        ...state,
        studentAnswers: action.studentAnswers,
      };
    case actions.INSTANT_SYNC_STUDENT_ANSWERS_FAILED:
      return {
        ...state,
      };
    case actions.INSTANT_RESTART_QUESTION:
      return {
        ...state,
        isRestarting: true,
      };
    case actions.INSTANT_RESTART_QUESTION_SUCCESS:
      return {
        ...state,
        isRestarting: false,
        answerToggled: false,
      };
    case actions.INSTANT_RESTART_QUESTION_FAILED:
      return {
        ...state,
        isRestarting: false,
      };
    case actions.INSTANT_RESTART_ALL_QUESTIONS:
      // use the same state "isRestarting" as INSTANT_RESTART_QUESTION action
      // since they don't really have many differences
      // if different state is required, can name it to "isRestartingAll"
      return {
        ...state,
        isRestarting: true,
      };
    case actions.INSTANT_RESTART_ALL_QUESTIONS_SUCCESS:
      return {
        ...state,
        isRestarting: false,
        answerToggled: false,
      };
    case actions.INSTANT_RESTART_ALL_QUESTIONS_FAILED:
      return {
        ...state,
        isRestarting: false,
      };
    case actions.INSTANT_TOGGLE_ANSWER: {
      return {
        ...state,
        answerToggled: !state.answerToggled,
      };
    }
    case actions.INSTANT_AUTHENTICATE:
      return {
        ...state,
        isAuthenticating: true,
      };
    case actions.INSTANT_AUTHENTICATE_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        authenticated: true,
      };
    case actions.INSTANT_AUTHENTICATE_FAILED:
      return {
        ...state,
        isAuthenticating: false,
        authenticationError: action.error
      };
    case actions.INSTANT_ACTIVATE_QUESTION:
      return {
        ...state,
        isActivating: true,
      };
    case actions.INSTANT_ACTIVATE_QUESTION_SUCCESS:
      return {
        ...state,
        isActivating: false,
        authenticated: true,
      };
    case actions.INSTANT_ACTIVATE_QUESTION_FAILED:
      return {
        ...state,
        isActivating: false,
      };
    case actions.INSTANT_CLEAR_QUESTION_TEACHER_SUCCESS:
      return teacherInitState;

    case actions.GET_INSTANT_QUESTIONS:
      return {
        ...state,
        isFetchingInstantQuestions: true,
      }
    case actions.GET_INSTANT_QUESTIONS_SUCCESS:
      return {
        ...state,
        instantQuestions: action.instantQuestions,
        isFetchingInstantQuestions: false,
      }
    case actions.GET_INSTANT_QUESTIONS_FAILED:
      return {
        ...state,
        isFetchingInstantQuestions: false,
      }
    default:
      return state;
  }
};
