const actions = {
  INSTANT_QUESTION_CHECKPIN: 'INSTANT_QUESTION_CHECKPIN',
  INSTANT_QUESTION_CHECKPIN_SUCCESS: 'INSTANT_QUESTION_CHECKPIN_SUCCESS',
  INSTANT_QUESTION_CHECKPIN_FAILED: 'INSTANT_QUESTION_CHECKPIN_FAILED',

  INSTANT_SYNC_QUESTION: 'INSTANT_SYNC_QUESTION',
  INSTANT_SYNC_QUESTION_SUCCESS: 'INSTANT_SYNC_QUESTION_SUCCESS',
  INSTANT_SYNC_QUESTION_FAILED: 'INSTANT_SYNC_QUESTION_FAILED',

  INSTANT_GET_QUESTION_SUCCESS: 'INSTANT_GET_QUESTION_SUCCESS',
  INSTANT_GET_QUESTION_FAILED: 'INSTANT_GET_QUESTION_FAILED',

  INSTANT_CLEAR_QUESTION: 'INSTANT_CLEAR_QUESTION',
  INSTANT_CLEAR_QUESTION_SUCCESS: 'INSTANT_CLEAR_QUESTION_SUCCESS',

  checkPin: (questionId, pin, history) => ({
    type: actions.INSTANT_QUESTION_CHECKPIN,
    questionId,
    pin,
    history,
  }),

  syncInstantQuestion: questionId => ({
    type: actions.INSTANT_SYNC_QUESTION,
    questionId,
  }),

  clearQuestion: () => ({
    type: actions.INSTANT_CLEAR_QUESTION,
  }),

};

export default actions;
