import { all, fork, put, takeLatest, cancel, select } from 'redux-saga/effects';
import makeToast, { toastType } from './QuizSessionToast';
import { QuizSessionApi } from '../../utils/axios';
import { rsfFirestore } from '../../utils/firebase';
import actions from './actions';
import QuizSessionSchema, {
  StudentAnswersSchema,
  StudentLoadedSchema,
  StudentEnteredSchema,
  QuizQuestionSchema,
} from './schemas';

let syncStudentEnteredTask = null;
let syncStudentLoadedTask = null;
let syncStudentAnswersTask = null;
let syncQuizSessionTask = null;

export function* syncStudentEntered(params) {
  const { sessionId, questionNumber } = params;

  if (syncStudentEnteredTask) yield cancel(syncStudentEnteredTask);

  syncStudentEnteredTask = yield fork(
    rsfFirestore.syncDocument,
    `quiz_sessions/${sessionId}/student_entered/${questionNumber}`,
    {
      transform: snapshot => {
        const { value, error } = StudentEnteredSchema.validate(snapshot.data());

        if (error) throw error;

        return Object.keys(value).length;
      },
      successActionCreator: studentEntered => ({
        type: actions.SYNC_STUDENT_ENTERED,
        studentEntered,
      }),
      failureActionCreator: error => {
        console.log('<syncStudentEntered>', error);
        makeToast(toastType.SYNC_STUDENT_ENTERED_FAILED);
        return { type: actions.SYNC_STUDENT_ENTERED_FAILED };
      },
    }
  );
}

export function* syncStudentLoaded(params) {
  const { sessionId, questionNumber } = params;

  if (syncStudentLoadedTask) yield cancel(syncStudentLoadedTask);

  syncStudentLoadedTask = yield fork(
    rsfFirestore.syncDocument,
    `quiz_sessions/${sessionId}/student_loaded/${questionNumber}`,
    {
      transform: snapshot => {
        const { value, error } = StudentLoadedSchema.validate(snapshot.data());

        if (error) throw error;

        return Object.keys(value).length;
      },
      successActionCreator: studentLoaded => ({
        type: actions.SYNC_STUDENT_LOADED,
        studentLoaded,
      }),
      failureActionCreator: error => {
        console.log('<syncStudentLoaded>', error);
        makeToast(toastType.SYNC_STUDENT_LOADED_FAILED);
        return { type: actions.SYNC_STUDENT_LOADED_FAILED };
      },
    }
  );
}

export function* syncStudentAnswers(params) {
  const { sessionId, questionNumber } = params;

  if (syncStudentAnswersTask) yield cancel(syncStudentAnswersTask);

  syncStudentAnswersTask = yield fork(
    rsfFirestore.syncDocument,
    `quiz_sessions/${sessionId}/student_answers/${questionNumber}`,
    {
      transform: snapshot => {
        const { value, error } = StudentAnswersSchema.validate(snapshot.data());

        if (error) throw error;

        return value;
      },
      successActionCreator: studentAnswers => ({
        type: actions.SYNC_STUDENT_ANSWERS,
        studentAnswers,
      }),
      failureActionCreator: error => {
        console.log('<syncStudentAnswers>', error);
        makeToast(toastType.SYNC_STUDENT_ANSWERS_FAILED);
        return { type: actions.SYNC_STUDENT_ANSWERS_FAILED };
      },
    }
  );
}

export function* syncQuizSession(params) {
  const { sessionId, history } = params;

  if (syncQuizSessionTask) yield cancel(syncQuizSessionTask);

  syncQuizSessionTask = yield fork(
    rsfFirestore.syncDocument,
    `quiz_sessions/${sessionId}`,
    {
      transform: snapshot => {
        const { value, error } = QuizSessionSchema.validate({
          shareCode: snapshot.get('shareCode'),
          name: snapshot.get('name'),
          status: snapshot.get('status'),
          totalQuestionNumber: snapshot.get('totalQuestionNumber'),
          progress: snapshot.get('progress'),
        });

        if (error) throw error;

        return value;
      },
      successActionCreator: quizSession => ({
        type: actions.SYNC_QUIZ_SESSION,
        quizSession,
      }),
      failureActionCreator: error => {
        console.log('<syncQuizSession>', error);
        history.push('/pages/quizsessions');
        makeToast(toastType.SYNC_QUIZ_SESSION_FAILED);
        return { type: actions.SYNC_QUIZ_SESSION_FAILED };
      },
    }
  );
}

export function* activateQuizSession(params) {
  const { sessionId, history } = params;

  try {
    if (!(sessionId && history)) throw new Error('Invalid action creator');

    const { status } = yield QuizSessionApi.activateQuizSession(sessionId);
    if (status === 200) {
      yield fork(() => syncQuizSession({ sessionId, history }));
      yield fork(() => syncStudentEntered({ sessionId, questionNumber: 0 }));
      yield fork(() => syncStudentLoaded({ sessionId, questionNumber: 0 }));

      yield put({ type: actions.ACTIVATE_QUIZ_SESSION_SUCCESS, sessionId });
      history.push('/pages/quizsessions');
    } else {
      throw new Error(`Activate Quiz Session failed with ${status}`);
    }
  } catch (error) {
    console.log('<activateQuizSession>', error);
    makeToast(toastType.ACTIVATE_QUIZ_SESSION_FAILED);
    yield put({ type: actions.ACTIVATE_QUIZ_SESSION_FAILED });
  }
}

export function* getQuizSessionQuestion(params) {
  const { sessionId, questionNumber } = params;

  try {
    if (!(sessionId && questionNumber)) {
      throw new Error('Invalid action creator');
    }

    const { status, data } = yield QuizSessionApi.getQuizSessionQuestion(
      sessionId,
      questionNumber
    );

    if (status === 200) {
      const { value: question, error } = QuizQuestionSchema.validate(data);

      if (error) throw error;

      yield put({
        type: actions.GET_QUIZ_SESSION_QUESTION_SUCCESS,
        question,
      });

      yield fork(() => syncStudentEntered({ sessionId, questionNumber }));
      yield fork(() => syncStudentLoaded({ sessionId, questionNumber }));
      yield fork(() => syncStudentAnswers({ sessionId, questionNumber }));
    } else {
      throw new Error(`Get Quiz Session Question failed with ${status}`);
    }
  } catch (error) {
    console.log('<getQuizSessionQuestion>', error);
    makeToast(toastType.GET_QUIZ_SESSION_QUESTION_FAILED);
    yield put({
      type: actions.GET_QUIZ_SESSION_QUESTION_FAILED,
    });
  }
}

export function* loadQuestion(params) {
  const { sessionId, questionNumber } = params;

  const QuizSession = yield select(state => state.QuizSession);
  try {
    if (questionNumber > QuizSession.totalQuestionNumber) {
      yield put({
        type: actions.FINISH_QUIZ_SESSION,
        sessionId,
      });
    } else {
      if (!(sessionId && questionNumber)) {
        throw new Error('Invalid action creator');
      }

      const { status } = yield QuizSessionApi.loadQuestion(
        sessionId,
        questionNumber
      );

      if (status === 200) {
        yield put({
          type: actions.LOAD_QUESTION_SUCCESS,
        });
      } else {
        throw new Error(`Load Question failed with ${status}`);
      }
    }
  } catch (error) {
    console.log('<loadQuestion>', error);
    makeToast(toastType.LOAD_QUESTION_FAILED);
    yield put({
      type: actions.LOAD_QUESTION_FAILED,
    });
  }
}

export function* startQuestion(params) {
  const { sessionId, questionNumber } = params;

  try {
    if (!(sessionId && questionNumber)) {
      throw new Error('Invalid action creator');
    }

    const { status } = yield QuizSessionApi.startQuestion(
      sessionId,
      questionNumber
    );

    if (status === 200) {
      yield put({
        type: actions.START_QUESTION_SUCCESS,
      });
    } else {
      throw new Error(`Start QUestion failed with ${status}`);
    }
  } catch (error) {
    console.log('<startQuestion>', error);
    makeToast(toastType.START_QUESTION_FAILED);
    yield put({
      type: actions.START_QUESTION_FAILED,
    });
  }
}

// server not implemented yet
export function* finishQuizSession(params) {
  const { sessionId } = params;

  if (syncStudentEnteredTask) yield cancel(syncStudentEnteredTask);
  if (syncStudentLoadedTask) yield cancel(syncStudentLoadedTask);
  if (syncStudentAnswersTask) yield cancel(syncStudentAnswersTask);
  if (syncQuizSessionTask) yield cancel(syncQuizSessionTask);

  try {
    const { status } = yield QuizSessionApi.finishQuizSession(sessionId);

    if (status === 200) {
      yield put({
        type: actions.FINISH_QUIZ_SESSION_SUCCESS,
      });
    } else {
      throw new Error(`Finish Quiz Session failed with ${status}`);
    }
  } catch (error) {
    makeToast(toastType.FINISH_QUIZ_SESSION_FAILED);
    yield put({
      type: actions.FINISH_QUIZ_SESSION_FAILED,
    });
  }
}

/** Proceed to the next step of the question */
export function* changeStep(params) {
  const { sessionId, stepNumber } = params;
  try {
    // calling the back end API to change step
    const { status } = yield QuizSessionApi.startQuestion(
      sessionId,
      stepNumber
    );

    if (status === 200) {
      console.log('update step successed');
      yield put({ type: actions.CHANGE_STEP_SUCCESS, step: stepNumber });
    } else {
      // yield put({ type: actions.CHANGE_STEP_FAILED });
      throw new Error(`Change Step failed with ${status}`);
    }
  } catch (error) {
    yield put({ type: actions.CHANGE_STEP_FAILED, error });
  }
}

function* resetQuizSession(params) {
  const { sessionId } = params;

  try {
    const { status } = yield QuizSessionApi.resetQuizSession(sessionId);

    if (status !== 200) throw new Error('Reset Failed with code ', status);

    // makeToast(toastType.RESET_QUIZ_SUCCESS);
    yield put({ type: actions.RESET_QUIZ_SESSION_SUCCESS });
    yield fork(() => syncStudentEntered({ sessionId, questionNumber: 0 }));
    yield fork(() => syncStudentLoaded({ sessionId, questionNumber: 0 }));
  } catch (err) {
    // makeToast(toastType.RESET_QUIZ_FAILED);
    yield put({ type: actions.RESET_QUIZ_SESSION_FAILED });
  }
}

function* resetSingleQuestion(params) {
  const { sessionId, currentQuestionNumber } = params;

  try {
    const { status } = yield QuizSessionApi.resetSingleQuestion(
      sessionId,
      currentQuestionNumber
    );

    if (status !== 200) throw new Error('Reset Failed with code ', status);

    // makeToast(toastType.RESET_QUIZ_SUCCESS);
    yield put({ type: actions.RESET_SINGLE_QUESTION_SUCCESS });
  } catch (err) {
    // makeToast(toastType.RESET_QUIZ_FAILED);
    yield put({ type: actions.RESET_SINGLE_QUESTION_FAILED });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_QUIZ_SESSION_QUESTION, getQuizSessionQuestion),
    takeLatest(actions.ACTIVATE_QUIZ_SESSION, activateQuizSession),
    takeLatest(actions.LOAD_QUESTION, loadQuestion),
    takeLatest(actions.START_QUESTION, startQuestion),
    takeLatest(actions.FINISH_QUIZ_SESSION, finishQuizSession),
    takeLatest(actions.CHANGE_STEP, changeStep),
    takeLatest(actions.RESET_QUIZ_SESSION, resetQuizSession),
    takeLatest(actions.RESET_SINGLE_QUESTION, resetSingleQuestion),

    // Development purpose
    // take(actions.devSimulateSubmitAnswerRiskAnalysis, devSimulateAnswer),
  ]);
}
