import actions from './actions';

const initState = {
  questions: [],
  isCreating: false,
  isFetching: false,
  isSaving: false,
  isDeleting: false,
  isNewQuestion: false,
  isEditable: false,
  enableEditView: false,
  currentQuestion: {},
  editableQuestion: {},
  //data: matrixDummyData,
};

export default function questionReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.QUESTION_GET_QUESTIONS:
      return {
        ...state,
        isFetching: true,
      };
    case actions.QUESTION_GET_QUESTION_SUCCESS:
      return {
        ...state,
        questions: action.questions,
        isFetching: false,
      };

    case actions.QUESTION_GET_QUESTION_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case actions.QUESTION_INIT_QUESTION:
      return {
        ...state,
        isNewQuestion: true,
        enableEditView: true,
        currentQuestion: {
          id: 'new',
          name: '',
          description: '',
          img: [],
          course: '',
          subject: '',
          textContent: '',
          type: 'multiple',
          difficulty: 0,
          choices: {},
          answers:[],
          pin: '',
        },
        editableQuestion: {
          id: 'new',
          name: '',
          description: '',
          img: [],
          course: '',
          subject: '',
          textContent: '',
          type: 'multiple',
          difficulty: 0,
          choices: {},
          answers: [],
        },
      };

    case actions.QUESTION_SELECT_CURRENT_QUESTION:
      {
        console.log('QUESTION_SELECT_CURRENT_QUESTION');
        const index = state.questions
          .map(question => question.id)
          .indexOf(action.questionId);
        if (index !== -1) {
          return {
            ...state,
            currentQuestion: state.questions[index],
          };
        }
      }
      break;

    case actions.QUESTION_TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
        editableQuestion: action.view ? { ...state.currentQuestion } : {},
      };

    case actions.QUESTION_EDIT_QUESTION:
      return {
        ...state,
        editableQuestion: { ...action.question },
      };

    case actions.QUESTION_SAVE_QUESTION:
      console.log('QUESTION_SAVE_QUESTION_TYPE', state);
      return {
        ...state,
        isSaving: true,
      };

    case actions.QUESTION_SAVE_QUESTION_SUCCESS:
      console.log('QUESTION_SAVE_QUESTION_SUCCESS');
      return {
        ...state,
        currentQuestion: action.question,
        editableQuestion: {},
        enableEditView: false,
        isSaving: false,
        isCreating: false,
        isNewQuestion: false,
      };

    case actions.QUESTION_SAVE_QUESTION_FAILED:
      console.log('QUESTION_SAVE_QUESTION_FAILED', state);
      return {
        ...state,
        isSaving: false,
      };

    case actions.QUESTION_CANCEL_CREATE_QUESTION:
      return {
        ...state,
        isNewQuestion: false,
        enableEditView: false,
        currentQuestion: {},
        editableQuestion: {},
      };

    case actions.QUESTION_DELETE_QUESTION:
      return {
        ...state,
        isDeleting: true,
      };

    case actions.QUESTION_DELETE_QUESTION_SUCCESS:
      console.log('stateInReducer2', state)
      return {
        ...state,
        isDeleting: false,
        enableEditView: false,
        questions: state.questions.filter(
          question => question.id !== action.questionId
        ),
      };

    case actions.QUESTION_DELETE_QUESTION_FAILED:
      console.log('stateInReducer', state)
      return {
        ...state,
        isDeleting: false,
      };

    case actions.QUESTION_CLEAR_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: {},
        editableQuestion: {},
      };
    default:
      return state;
  }
  return state;
}
