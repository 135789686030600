import actions from './actions';

const initState = {
  quizzes: [],
  isFetching: false,
  isFetchingQuestions: false,
  isSaving: false,
  isDeleting: false,
  isReiniting: false,
  isNewQuiz: false,
  enableEditView: false,
  currentQuiz: {},
  editableQuiz: {},
  currentQuizQuestions: [],
  editableQuizQuestions: [],
};

export default function quizReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.QUIZ_GET_QUIZZES:
      return {
        ...state,
        isFetching: true,
      };
    case actions.QUIZ_GET_QUIZZES_SUCCESS:
      return {
        ...state,
        quizzes: action.quizzes,
        isFetching: false,
      };
    case actions.QUIZ_GET_QUIZZES_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case actions.QUIZ_GET_QUIZ_QUESTIONS:
      return {
        ...state,
        isFetchingQuestions: true,
      };

    case actions.QUIZ_GET_QUIZ_QUESTIONS_SUCCESS:
      return {
        ...state,
        isFetchingQuestions: false,
        currentQuizQuestions: action.quizQuestions,
        editableQuizQuestions: action.quizQuestions,
      };

    case actions.QUIZ_GET_QUIZ_QUESTIONS_FAILED:
      return {
        ...state,
        isFetchingQuestions: false,
      };

    case actions.QUIZ_UPDATE_QUIZ_QUESTIONS:
      return {
        ...state,
        editableQuizQuestions: action.quizQuestions,
      };

    case actions.QUIZ_RESET_QUIZ_QUESTIONS:
      return {
        ...state,
        editableQuizQuestions: state.currentQuizQuestions,
      };

    case actions.QUIZ_EDIT_QUIZ_QUESTIONS:
      return {
        ...state,
        isFetchingQuestions: true,
      };

    case actions.QUIZ_EDIT_QUIZ_QUESTIONS_SUCCESS:
      return {
        ...state,
        isFetchingQuestions: false,
        currentQuizQuestions: action.quizQuestions,
        editableQuizQuestions: action.quizQuestions,
      };

    case actions.QUIZ_EDIT_QUIZ_QUESTIONS_FAILED:
      return {
        ...state,
        isFetchingQuestions: false,
      };

    case actions.QUIZ_INIT_QUIZ:
      return {
        ...state,
        isNewQuiz: true,
        enableEditView: true,
        currentQuiz: {
          id: 'new',
          name: '',
          status: '',
          totalQuestionNumber: 0,
        },
        editableQuiz: {
          id: 'new',
          name: '',
          status: '',
          totalQuestionNumber: 0,
        },
      };

    case actions.QUIZ_SELECT_CURRENT_QUIZ: {
      const index = state.quizzes.map(quiz => quiz.id).indexOf(action.quizId);
      if (index !== -1) {
        return {
          ...state,
          currentQuiz: state.quizzes[index],
        };
      }
    }
      break;
    case actions.QUIZ_TOGGLE_VIEW:
      return {
        ...state,
        enableEditView: action.view,
        editableQuiz: action.view ? { ...state.currentQuiz } : {},
      };

    case actions.QUIZ_EDIT_QUIZ:
      return {
        ...state,
        editableQuiz: { ...action.quiz },
      };

    case actions.QUIZ_SAVE_QUIZ:
      return {
        ...state,
        isSaving: true,
      };

    case actions.QUIZ_SAVE_QUIZ_SUCCESS:
      return {
        ...state,
        currentQuiz: action.quiz,
        quizzes: state.quizzes.map(quiz => (quiz.shareCode ===
          action.quiz.shareCode ? { ...action.quiz, id: action.quiz.shareCode } : quiz)),
        editableQuiz: {},
        enableEditView: false,
        isSaving: false,
        isNewQuiz: false,
      };

    case actions.QUIZ_SAVE_QUIZ_FAILED:
      return {
        ...state,
        isSaving: false,
      };

    case actions.QUIZ_CANCEL_CREATE_QUIZ:
      return {
        ...state,
        isNewQuiz: false,
        enableEditView: false,
        currentQuiz: {},
        editableQuiz: {},
      };

    case actions.QUIZ_DELETE_QUIZ:
      return {
        ...state,
        isDeleting: true,
      };

    case actions.QUIZ_DELETE_QUIZ_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        enableEditView: false,
        quizzes: state.quizzes.filter(quiz => quiz.id !== action.quizId),
      };

    case actions.QUIZ_DELETE_QUIZ_FAILED:
      return {
        ...state,
        isDeleting: false,
      };

    case actions.QUIZ_CLEAR_CURRENT_QUIZ:
      return {
        ...state,
        currentQuiz: {},
        editableQuiz: {},
      };

    case actions.QUIZ_REINIT_QUIZ:
      return {
        ...state,
        isReiniting: true,
      };
    case actions.QUIZ_REINIT_QUIZ_SUCCESS:
      return {
        ...state,
        isReiniting: false,
      };

    case actions.QUIZ_REINIT_QUIZ_FAILED:
      return {
        ...state,
        isReiniting: false,
      };

    default:
      return state;
  }
  return state;
}
