import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import './polyfills';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import './ui/assets/base.scss';
import AppMain from './ui/Layout/AppMain';

const rootElement = document.getElementById('root');

const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <Component />
      </HashRouter>
    </Provider>,
    rootElement
  );
};

renderApp(AppMain);

if (module.hot) {
  module.hot.accept('./ui/Layout/AppMain', () => {
    const NextApp = require('./ui/Layout/AppMain').default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
