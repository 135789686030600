import { toast } from 'react-toastify';

export const toastType = {
  CHECKPIN_FAILED: 'CHECKPIN_FAILED',
  RESTART_QUESTION_FAILED: 'RESTART_QUESTION_FAILED',
  GET_QUESTION_FAILED: 'GET_QUESTION_FAILED',
  REMOTE_TOGGLE_ANSWER_FAILED: 'REMOTE_TOGGLE_ANSWER_FAILED',
  RESTART_ALL_QUESTIONS_SUCCESSFUL: 'RESTART_ALL_QUESTIONS_SUCCESSFUL',
  RESTART_ALL_QUESTIONS_FAILED: 'RESTART_ALL_QUESTIONS_FAILED',
};

const commonToastParam = {
  toastId: 'nonActive',
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
};

export default (type) => {
  switch (type) {
    case toastType.CHECKPIN_FAILED:
      toast.error('Incorrect PIN', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.RESTART_QUESTION_FAILED:
      toast.error('Restart Question Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.GET_QUESTION_FAILED:
      toast.error('Get Question Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.REMOTE_TOGGLE_ANSWER_FAILED:
      toast.error(
        'toggle answer failed, teacher cannot update the field showAnswer in firebase',
        {
          toastId: 'nonActive',
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
        }
      );
      break;
    case toastType.RESTART_ALL_QUESTIONS_SUCCESSFUL:
      toast.success('Successfully Reset All Questions', commonToastParam);
      break;
    case toastType.RESTART_ALL_QUESTIONS_FAILED:
      toast.error('Restart All Questions Failed', commonToastParam);
      break;
    default:
      toast.error('😊 Unexpected Error Please Refresh', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
  }
};
