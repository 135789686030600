import actions from './actions';
import StudentReducer, {
  initState as StudentInitState,
} from './Student/reducer';

const initState = {
  id: null,
  question: null,
  questionRef: null,
  questionNumber: 0,
  teacherName: null,
  teacherUUID: null,
  teacherCurrent: null,
  token: null,
  status: null,
  round: null,
  isChecking: false,
  isLoadingTeacherCurrent: false,
  isLoadingQuestion: false,
  Student: StudentInitState,
  correctAnswer: {},
  showAnswer: false
};

function instantQuestionReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.INSTANT_QUESTION_CHECKPIN:
      return {
        ...state,
        isChecking: true,
      };
    case actions.INSTANT_QUESTION_CHECKPIN_SUCCESS:
      return {
        ...state,
        isChecking: false,
        token: action.token,
      };
    case actions.INSTANT_QUESTION_CHECKPIN_FAILED:
      return {
        ...state,
        isChecking: false,
      };
    case actions.INSTANT_STUDENT_SYNC_QUESTION:
      return {
        ...state,
        isLoadingQuestion: true,
      };
    case actions.INSTANT_STUDENT_SYNC_QUESTION_SUCCESS:
      return {
        ...state,
        ...action.instantQuestion,
      };
    case actions.INSTANT_STUDENT_SYNC_QUESTION_FAILED:
      return {
        ...state,
        isLoadingQuestion: false,
      };
    case actions.INSTANT_GET_QUESTION_SUCCESS:
      return {
        ...state,
        question: action.question,
        isLoadingQuestion: false,
      };
    case actions.INSTANT_GET_QUESTION_FAILED:
      return {
        ...state,
        isLoadingQuestion: false,
      };
    case actions.INSTANT_SET_TEACHER_UUID:
      return {
        ...state,
        teacherUUID: action.uuid,
        teacherName: action.teacherName,
      };
    case actions.INSTANT_SYNC_TEACHER_CURRENT:
      return {
        ...state,
        isLoadingTeacherCurrent: true,
      };
    case actions.INSTANT_SYNC_TEACHER_CURRENT_SUCCESS:
      return {
        ...state,
        teacherCurrent: action.current,
        isLoadingTeacherCurrent: false,
      };
    case actions.INSTANT_SYNC_TEACHER_CURRENT_FAILED:
      return {
        ...state,
        isLoadingTeacherCurrent: false,
      };
    case actions.INSTANT_SET_QUESTION_CORRECT_ANSWER_SUCCESS:
      return {
        ...state,
        showAnswer: action.showAnswer,
        question: { ...state.question, answers: action.correctAnswer },
      };
    case actions.INSTANT_SET_QUESTION_CORRECT_ANSWER_FAILED:
      return {
        ...state,
        showAnswer: false,
        question: { ...state.question, answers: {} },
      };
    // case actions.INSTANT_CLEAR_QUESTION_SUCCESS:
    //   return {
    //     ...state,
    //     showAnswer: false,
    //     question: null
    //   }
    default:
      return state;
  }
}

export default (state = initState, { type, ...action }) => {
  const { Teacher, Student, ...InstantQuestion } = state;
  return {
    ...instantQuestionReducer(InstantQuestion, { type, ...action }),
    // Import reducer for teacher and student
    Student: StudentReducer(Student, { type, ...action }),
  };
};
