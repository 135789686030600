// actions for SetMarker
const actions = {
  TOGGLE_HEATMAP: 'TOGGLE_HEATMAP',
  GET_CORRECT_ANSWERS: 'GET_CORRECT_ANSWERS',
  TOGGLE_CORRECT_ANSWERS: 'TOGGLE_CORRECT_ANSWERS',
  // maybe it will be needed when teacher edits a question
  TEACHER_ADD_ANSWER: 'TEACHER_ADD_ANSWER',

  toggleHeatmap: () => ({
    type: actions.TOGGLE_HEATMAP,
  }),
  panoLoaded: (panoInstance) => ({
    type: actions.PANO_LOADED,
    panoInstance,
  }),
  getCorrectAnswers: (answers) => ({
    type: actions.GET_CORRECT_ANSWERS,
    answers
  }),
  toggleCorrectAnswers: () => ({
    type: actions.TOGGLE_CORRECT_ANSWERS,
  }),
  teacherAddAnswer: (answer) => ({
    type: actions.TEACHER_ADD_ANSWER,
    answer,
  }),
};

export default actions;
