const actions = {
  INSTANT_QUESTION_STUDENT_CHOOSE_ANSWER:
    'INSTANT_QUESTION_STUDENT_CHOOSE_ANSWER',
  INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER:
    'INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER',
  INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SUCCESS:
    'INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SUCCESS',
  INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_FAIL:
    'INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_FAIL',
  INSTANT_QUESTION_STUDENT_GENERATE_UUID:
    'INSTANT_QUESTION_STUDENT_GENERATE_UUID',
  INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS:
    'INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS',
  INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE:
    'INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE',
  INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_POLL:
    'INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_POLL',
  INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SET_MARKER:
    'INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SET_MARKER',
  INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MATRIX:
    'INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MATRIX',
  INSTANT_QUESTION_STUDENT_CLEAN_ANSWER: 'INSTANT_QUESTION_STUDENT_CLEAN_ANSWER',

  cleanAnswer: () => ({
    type: actions.INSTANT_QUESTION_STUDENT_CLEAN_ANSWER,
  }),
  chooseAnswer: ({ answer }) => ({
    type: actions.INSTANT_QUESTION_STUDENT_CHOOSE_ANSWER,
    answer,
  }),
  generateUuid: () => ({
    type: actions.INSTANT_QUESTION_STUDENT_GENERATE_UUID,
  }),
  submitAnswerMultipleChoice: ({ answer }) => ({
    type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE,
    answer,
  }),
  submitAnswerPoll: ({ answer }) => ({
    type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_POLL,
    answer,
  }),
  submitAnswerSetMarker: answer => ({
    type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SET_MARKER,
    answer,
  }),
  submitAnswerRiskAnalysis: answer => ({
    type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS,
    answer,
  }),
  submitAnswerMatrix: answer => ({
    type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MATRIX,
    answer,
  })
};

export default actions;
