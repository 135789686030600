const actions = {
  INSTANT_TEACHER_INIT: 'INSTANT_TEACHER_INIT',

  INSTANT_RESTART_QUESTION: 'INSTANT_RESTART_QUESTION',
  INSTANT_RESTART_QUESTION_SUCCESS: 'INSTANT_RESTART_QUESTION_SUCCESS',
  INSTANT_RESTART_QUESTION_FAILED: 'INSTANT_RESTART_QUESTION_FAILED',

  INSTANT_RESTART_ALL_QUESTIONS: 'INSTANT_RESTART_ALL_QUESTIONS',
  INSTANT_RESTART_ALL_QUESTIONS_SUCCESS:
    'INSTANT_RESTART_ALL_QUESTIONS_SUCCESS',
  INSTANT_RESTART_ALL_QUESTIONS_FAILED: 'INSTANT_RESTART_ALL_QUESTIONS_FAILED',

  INSTANT_TOGGLE_ANSWER: 'INSTANT_TOGGLE_ANSWER',

  INSTANT_SYNC_STUDENT_ANSWERS_SUCCESS: 'INSTANT_SYNC_STUDENT_ANSWERS_SUCCESS',
  INSTANT_SYNC_STUDENT_ANSWERS_FAILED: 'INSTANT_SYNC_STUDENT_ANSWERS_FAILED',

  INSTANT_AUTHENTICATE: 'INSTANT_AUTHENTICATE',
  INSTANT_AUTHENTICATE_SUCCESS: 'INSTANT_AUTHENTICATE_SUCCESS',
  INSTANT_AUTHENTICATE_FAILED: 'INSTANT_AUTHENTICATE_FAILED',

  INSTANT_ACTIVATE_QUESTION: 'INSTANT_ACTIVATE_QUESTION',
  INSTANT_ACTIVATE_QUESTION_SUCCESS: 'INSTANT_ACTIVATE_QUESTION_SUCCESS',
  INSTANT_ACTIVATE_QUESTION_FAILED: 'INSTANT_ACTIVATE_QUESTION_FAILED',

  INSTANT_CLEAR_QUESTION_TEACHER: 'INSTANT_CLEAR_QUESTION_TEACHER',
  INSTANT_CLEAR_QUESTION_TEACHER_SUCCESS:
    'INSTANT_CLEAR_QUESTION_TEACHER_SUCCESS',

  INSTANT_FINISH_QUESTION: 'INSTANT_FINISH_QUESTION',
  INSTANT_FINISH_QUESTION_SUCCESS: 'INSTANT_FINISH_QUESTION_SUCCESS',
  INSTANT_FINISH_QUESTION_FAILED: 'INSTANT_FINISH_QUESTION_FAILED',

  GET_INSTANT_QUESTIONS: 'GET_INSTANT_QUESTIONS',
  GET_INSTANT_QUESTIONS_SUCCESS: 'GET_INSTANT_QUESTIONS_SUCCESS',
  GET_INSTANT_QUESTIONS_FAILED: 'GET_INSTANT_QUESTIONS_FAILED',

  initTeacher: (questionId) => ({
    type: actions.INSTANT_TEACHER_INIT,
    questionId,
  }),

  restartQuestion: (questionId) => ({
    type: actions.INSTANT_RESTART_QUESTION,
    questionId,
  }),

  restartAllQuestions: (startingQuestionId, prevId = "_", nextId = "_") => ({
    type: actions.INSTANT_RESTART_ALL_QUESTIONS,
    questionId: startingQuestionId,
    prevId,
    nextId
  }),

  toggleAnswer: () => ({
    type: actions.INSTANT_TOGGLE_ANSWER,
  }),

  authenticate: (questionId, token) => ({
    type: actions.INSTANT_AUTHENTICATE,
    questionId,
    token,
  }),

  activateQuestion: (questionId, token, history) => ({
    type: actions.INSTANT_ACTIVATE_QUESTION,
    questionId,
    token,
    history,
  }),

  clearQuestionTeacher: () => ({
    type: actions.INSTANT_CLEAR_QUESTION_TEACHER,
  }),

  finishQuestion: (questionId) => ({
    type: actions.INSTANT_FINISH_QUESTION,
    questionId,
  }),

  getInstantQuestions: () => ({
    type: actions.GET_INSTANT_QUESTIONS,
  }),
};

export default actions;
