import actions from './actions';

const initState = {
  token: '',
  err: '',
  user: {},
  isFetching: false,
};
export default function authReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.AUTH_TRADE_FOR_TOKEN:
      return {
        ...state,
        isFetching: true,
      };
    case actions.AUTH_TRADE_FOR_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
        token: action.token,
        isFetching: false,
      };
    case actions.AUTH_TRADE_FOR_TOKEN_FAILED:
      return {
        ...state,
        err: action.err,
        isFetching: false,
      };
    default:
      return state;
  }
}
