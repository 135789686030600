import { all, takeLatest, select, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from './actions';
import { rsfFirestore } from '../../../utils/firebase';

export function* submitAnswerMulipleChoice(params) {
  const { InstantQuestionStudent } = yield select();
  const { answer } = params;
  const { id, Student } = InstantQuestionStudent;
  const { studentId } = Student;
  const data = {};
  data[answer] = { [studentId]: true };
  try {
    toast.info('Submitting Answer......', {
      toastId: 'submitting',
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
    yield rsfFirestore.setDocument(
      `instant_questions/${id.toLowerCase()}/answers/0`,
      data,
      { merge: true }
    );
    toast.update('submitting', {
      render: 'Answer Submitted Successfully',
      type: toast.TYPE.SUCCESS,
    });
    yield put({ type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SUCCESS });
  } catch (error) {
    toast.error(
      '😔 Something Wrong when submitting the Answer, please try agin',
      {
        toastId: 'nonActive',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      }
    );
    console.log(error);
    yield put({ type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_FAIL });
  }
}

export function* submitAnswerPoll(params) {
  const { InstantQuestionStudent } = yield select();
  const { answer } = params;
  const { id, Student } = InstantQuestionStudent;
  const { studentId } = Student;

  const data = {};
  answer.forEach(option => {
    data[option] = { [studentId]: true };
  });
  try {
    toast.info('Submitting Answer......', {
      toastId: 'submitting',
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
    yield rsfFirestore.setDocument(
      `instant_questions/${id.toLowerCase()}/answers/0`,
      data,
      { merge: true }
    );
    toast.update('submitting', {
      render: 'Answer Submitted Successfully',
      type: toast.TYPE.SUCCESS,
    });
    yield put({ type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SUCCESS });
  } catch (error) {
    toast.error(
      '😔 Something Wrong when submitting the Answer, please try agin',
      {
        toastId: 'nonActive',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      }
    );
    yield put({ type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_FAIL });
  }
}

function* submitAnswer(
  params,
  defaultParams = { sessionId: '', questionNumber: 0 }
) {
  const { data } = params;
  let { sessionId, questionNumber } = params;

  if (!sessionId) {
    sessionId = defaultParams.sessionId;
  }

  if (!questionNumber || questionNumber <= 0) {
    // TODO: should throw error in production environment
    questionNumber = defaultParams.questionNumber;
  }
  try {
    toast.info('Submitting Answer......', {
      toastId: 'submitting',
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
    const path = `instant_questions/${sessionId.toLowerCase()}/answers/${questionNumber}`;
    yield rsfFirestore.setDocument(path, data, { merge: true });
    toast.update('submitting', {
      render: 'Answer Submitted Successfully',
      type: toast.TYPE.SUCCESS,
    });
    yield put({ type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SUCCESS });
  } catch (error) {
    toast.error(
      '😔 Something Wrong when submitting the Answer, please try agin',
      {
        toastId: 'nonActive',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      }
    );
    console.log(error);
    yield put({ type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_FAIL });
  }
}

// TODO: avoid using select
export function* submitAnswerSetMarker(answer) {
  const { InstantQuestionStudent } = yield select();
  const { id, Student } = InstantQuestionStudent;
  const { studentId } = Student;

  const data = {};
  data[studentId] = { ...answer.answer };

  const params = {
    data,
    sessionId: id,
    questionNumber: 0,
  };
  yield* submitAnswer(params);
}

/**
 * Submit answer for risk analysis question
 * @param {object} parameters Parameters of the function
 *    Structure: { sessionId: string, nickname, questionNumber: number|string, answer: {  } }
 */
export function* submitAnswerRiskAnalysis(params) {
  const { InstantQuestionStudent } = yield select();
  // const { answer } = params;
  const { id, Student } = InstantQuestionStudent;
  const { studentId } = Student;
  const { answer: rating } = params.answer;

  const data = {};
  data[studentId] = rating;

  const newParams = { data, sessionId: id, questionNumber: 0 };
  console.log(newParams);
  yield* submitAnswer(newParams);
}

export function* submitAnswerMatrix(params) {
  const { InstantQuestionStudent } = yield select();
  const { answer } = params;
  const { id, Student } = InstantQuestionStudent;
  const { studentId } = Student;
  const data = {};
  data[studentId] = answer;
  console.log('InSaga', InstantQuestionStudent, answer, data[studentId])

  try {
    toast.info('Submitting Answer......', {
      toastId: 'submitting',
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
    yield rsfFirestore.setDocument(
      `instant_questions/${id.toLowerCase()}/answers/0`,
      data,
      { merge: true }
    );
    toast.update('submitting', {
      render: 'Answer Submitted Successfully',
      type: toast.TYPE.SUCCESS,
    });
    yield put({ type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SUCCESS });
  } catch (error) {
    toast.error(
      '😔 Something Wrong when submitting the Answer, please try again',
      {
        toastId: 'nonActive',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      }
    );
    console.log(error);
    yield put({ type: actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_FAIL });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER, submitAnswer),
    takeLatest(
      actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE,
      submitAnswerMulipleChoice
    ),
    takeLatest(
      actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_POLL,
      submitAnswerPoll
    ),
    takeLatest(
      actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SET_MARKER,
      submitAnswerSetMarker
    ),
    takeLatest(
      actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS,
      submitAnswerRiskAnalysis
    ),
    takeLatest(
      actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MATRIX,
      submitAnswerMatrix
    )
  ]);
}
