const actions = {
  GET_SUBJECTS: 'GET_SUBJECTS',
  GET_SUBJECTS_SUCCESS: 'GET_SUBJECTS_SUCCESS',
  GET_SUBJECTS_FAILED: 'GET_SUBJECTS_FAILED',

  GET_SUBJECT_QUIZZES: 'GET_SUBJECT_QUIZZES',
  GET_SUBJECT_QUIZZES_SUCCESS: 'GET_SUBJECT_QUIZZES_SUCCESS',
  GET_SUBJECT_QUIZZES_FAILED: 'GET_SUBJECT_QUIZZES_FAILED',

  SET_CURRENT_SUBJECT_QUIZ: 'SET_CURRENT_SUBJECT_QUIZ',

  SET_CURRENT_PATH: 'SET_CURRENT_PATH',

  getSubjects: ({ page = 1, perPage = 100 }) => ({
    type: actions.GET_SUBJECTS,
    page,
    perPage,
  }),

  getSubjectQuizzes: ({ page = 1, perPage = 100 }) => ({
    type: actions.GET_SUBJECT_QUIZZES,
    page,
    perPage,
  }),

  setCurrentSubjectQuiz: ({ id }) => ({
    type: actions.SET_CURRENT_SUBJECT_QUIZ,
    id,
  }),

  setCurrentPath: currentPath => ({
    type: actions.SET_CURRENT_PATH,
    currentPath,
  }),
};

export default actions;
