import Joi from '@hapi/joi';

export const ProgressSchema = Joi.object({
  start: Joi.bool().required(),
  load: Joi.bool().required(),
  startTimestamp: Joi.string()
    .allow('')
    .required(),
  currentQuestionNumber: Joi.number().required(),
  step: Joi.number().required(),
});

export const AuthorSchema = Joi.object({
  googleUserId: Joi.string().required(),
  name: Joi.string().required(),
  email: Joi.string()
    // .email({ tlds: { allow: true } })
    .required(),
  picture: Joi.string().required(),
});

export const StudentAnswersSchema = Joi.object();

export const StudentLoadedSchema = Joi.object();

export const StudentEnteredSchema = Joi.object();

export const QuizQuestionSchema = Joi.object({
  id: Joi.string().required(),
  name: Joi.string().required(),
  description: Joi.string().required(),
  subject: Joi.string().required(),
  course: Joi.string().required(),
  difficulty: Joi.number().required(),
  questionContent: Joi.object(),
  answers: Joi.any(),
  type: Joi.string().required(),
  duration: Joi.number().required(),
  totalSteps: Joi.string(),
  img: Joi.array(),
  allowOptions: Joi.number(),
  // createdBy: AuthorSchema,
});

export default Joi.object({
  shareCode: Joi.string()
    .alphanum()
    .required(),
  name: Joi.string().required(),
  status: Joi.any()
    .allow('open', 'close', 'active')
    .required(),
  totalQuestionNumber: Joi.number().required(),
  progress: ProgressSchema.required(),
  createdBy: AuthorSchema,
});
