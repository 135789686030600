import { v4 as uuid } from 'uuid';
import actions from './actions';

export const initState = {
  answer: null,
  answeredQuestion: false,
  studentId: null,
};

export default function instantQuestionStudentReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.INSTANT_QUESTION_STUDENT_CHOOSE_ANSWER:
      return {
        ...state,
        answer: action.answer,
      };
    case actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE:
    case actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_POLL:
    case actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS:
    case actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_MATRIX:
      return {
        ...state,
        answeredQuestion: true,
      };
    case actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_SET_MARKER:
      return {
        ...state,
        answeredQuestion: true,
        answer: action.answer,
      };
    case actions.INSTANT_QUESTION_STUDENT_SUBMIT_ANSWER_FAIL:
      return {
        ...state,
        answeredQuestion: false,
      };
    case actions.INSTANT_QUESTION_STUDENT_GENERATE_UUID:
      return {
        ...state,
        studentId: uuid(),
      };
    case actions.INSTANT_QUESTION_STUDENT_CLEAN_ANSWER:
      return {
        ...state,
        answeredQuestion: false,
        answer: null,
      };
    default:
      return state;
  }
}
