import Axios from 'axios';
import { all, put, takeLatest } from 'redux-saga/effects';
import actions from './actions';

export function* getSubjects(params) {
  const { page, perPage } = params;
  let res = null;
  try {
    res = yield Axios.get(
      'https://virtserver.swaggerhub.com/darylzzz/Otaro/1.0.0/subjects',
      {
        page,
        perPage,
      }
    );
  } catch (err) {
    yield put({ type: actions.GET_SUBJECTS_FAILED });
  }

  if (res.data && res.data.success) {
    yield put({
      type: actions.GET_SUBJECTS_SUCCESS,
      subjects: res.data.subjects,
    });
  } else {
    yield put({ type: actions.GET_SUBJECTS_FAILED });
  }
}

export function* getSubjectQuizzes(params) {
  const { page, perPage } = params;
  let res = null;
  try {
    res = yield Axios.get(
      'https://virtserver.swaggerhub.com/darylzzz/Otaro/1.0.0/subjects/1/quizzes',
      {
        page,
        perPage,
      }
    );
  } catch (err) {
    yield put({ type: actions.GET_SUBJECT_QUIZZES_FAILED });
  }

  if (res.data && res.data.success) {
    yield put({
      type: actions.GET_SUBJECT_QUIZZES_SUCCESS,
      subjectQuizzes: res.data.quizzes,
    });
  } else {
    yield put({ type: actions.GET_SUBJECTS_FAILED });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_SUBJECTS, getSubjects),
    takeLatest(actions.GET_SUBJECT_QUIZZES, getSubjectQuizzes),
  ]);
}
