import { all, put, takeLatest, cancel, fork, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import actions from './actions';
import { rsfFirestore } from '../../utils/firebase';
import StudentSaga from './Student/saga';
import InstantQuestionSchema from './schemas';

let syncTeacherCurrentTask = null;
let teacherUUID = null;
let syncInstantQuestionTask = null;
let syncTeacherShowAnswer = null;

function* getTeacherUUID(teacherName) {
  try {
    const snapshot = yield call(
      rsfFirestore.getDocument,
      '/system/name_user_mapping'
    );
    teacherUUID = snapshot.data()[teacherName];
    yield put({
      type: actions.INSTANT_SET_TEACHER_UUID,
      uuid: teacherUUID,
      teacherName,
    });
  } catch (error) {
    console.log(error);
  }
  return '';
}
const formatQuestion = question => {
  const { type } = question;
  switch (type) {
    case 'poll':
    case 'multiple': {
      const {
        answers: correctAnswers,
        choices: choicesSet,
        ...rest
      } = question;
      return {
        ...rest,
        questionContent: {
          choicesSet,
          correctAnswers,
        },
      };
    }
    case 'setMarker':
    case 'riskAnalysis':
      return question;
    case 'chart':
      return question;
    case 'matrix':
      return question;
    case 'classify':
      return question;
    case 'fillInBlanks':
      return question;
    default:
      throw new Error('Question Type Not Found.');
  }
};

export function* getQuestion(params) {
  const { instantQuestion } = params;
  const { questionRef } = instantQuestion;

  try {
    const snapshot = yield call(rsfFirestore.getDocument, questionRef);
    const question = formatQuestion(snapshot.data());
    yield put({ type: actions.INSTANT_GET_QUESTION_SUCCESS, question });
    if (syncTeacherShowAnswer) yield cancel(syncTeacherShowAnswer);
    syncTeacherShowAnswer = yield fork(
      rsfFirestore.syncDocument,
      `instant_questions/${instantQuestion.id}`,
      {
        // eslint-disable-next-line consistent-return
        successActionCreator: instantQuestionSnapShot => {
          if (instantQuestionSnapShot.get('showAnswer') !== undefined) {
            return {
              type: actions.INSTANT_SET_QUESTION_CORRECT_ANSWER,
              questionSnapshot: snapshot,
              showAnswer: instantQuestionSnapShot.get('showAnswer'),
              questionInfo: question,
            };
          }
        }
      }
    );
  } catch (error) {
    yield put({ type: actions.INSTANT_GET_QUESTION_FAILED, error });
  }
}
export function* syncInstantQuestion(params) {
  const { questionId } = params;

  if (syncInstantQuestionTask) yield cancel(syncInstantQuestionTask);
  syncInstantQuestionTask = yield fork(
    rsfFirestore.syncDocument,
    `instant_questions/${questionId}`,
    {
      transform: snapshot => {
        const { value, error } = InstantQuestionSchema.validate({
          id: snapshot.get('id'),
          status: snapshot.get('status'),
          questionRef: snapshot.get('question'),
          round: snapshot.get('round'),
          questionNumber: snapshot.get('questionNumber')
        });

        if (error) throw error;

        return value;
      },
      successActionCreator: instantQuestion => ({
        type: actions.INSTANT_STUDENT_SYNC_QUESTION_SUCCESS,
        instantQuestion,
      }),
      failureActionCreator: error => ({
        type: actions.INSTANT_STUDENT_SYNC_QUESTION_FAILED,
        error,
      }),
    }
  );
}

export function* syncTeacherCurrent(params) {
  const { teacherName } = params;
  if (teacherUUID === null) yield getTeacherUUID(teacherName);
  if (syncTeacherCurrentTask) yield cancel(syncTeacherCurrentTask);

  syncTeacherCurrentTask = yield fork(
    rsfFirestore.syncDocument,
    `teacher_currents/${teacherUUID}`,
    {
      transform: snapshot => {
        const value = {
          current: snapshot.data().current,
        };
        return value;
      },
      successActionCreator: value => ({
        type: actions.INSTANT_SYNC_TEACHER_CURRENT_SUCCESS,
        current: value.current,
      }),
      failureActionCreator: error => ({
        type: actions.INSTANT_STUDENT_SYNC_QUESTION_FAILED,
        error,
      }),
    }
  );
}

export function* clearQuestion() {
  if (syncInstantQuestionTask) yield cancel(syncInstantQuestionTask);
  if (syncTeacherShowAnswer) yield cancel(syncTeacherShowAnswer);
  yield put({ type: actions.INSTANT_CLEAR_QUESTION_SUCCESS });
}

export function* syncInstantQuestionCorrectAnswer(params) {
  const { questionSnapshot, showAnswer, questionInfo } = params || null;
  if (!questionSnapshot) {
    toast.warn('Sync correct answer failed with null value of question snapshot', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
    yield put({
      type: actions.INSTANT_SET_QUESTION_CORRECT_ANSWER_FAILED
    });
  }

  const correctAnswer = questionSnapshot.get('answers');

  // if (!correctAnswer || JSON.stringify(correctAnswer) === '{}') {
  //   toast.warn('Sync correct answer failed with empty value of correct answer', {
  //     position: 'top-center',
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: false,
  //     pauseOnHover: true,
  //     draggable: true,
  //   });
  //   yield put({
  //     type: actions.INSTANT_SET_QUESTION_CORRECT_ANSWER_FAILED
  //   });
  // }


  // toast.success('Successfully sync correct answers', {
  //   position: 'top-center',
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: false,
  //   pauseOnHover: true,
  //   draggable: true,
  // });
  yield put({
    type: actions.INSTANT_SET_QUESTION_CORRECT_ANSWER_SUCCESS,
    correctAnswer,
    showAnswer
  });
}

export default function* rootSaga() {
  yield all([
    StudentSaga(),
    takeLatest(actions.INSTANT_STUDENT_SYNC_QUESTION, syncInstantQuestion),
    takeLatest(actions.INSTANT_STUDENT_SYNC_QUESTION_SUCCESS, getQuestion),
    takeLatest(actions.INSTANT_SYNC_TEACHER_CURRENT, syncTeacherCurrent),
    takeLatest(actions.INSTANT_CLEAR_QUESTION, clearQuestion),
    takeLatest(actions.INSTANT_SET_QUESTION_CORRECT_ANSWER, syncInstantQuestionCorrectAnswer)
  ]);
}
