import {
  all,
  put,
  takeLatest,
  takeLeading,
  cancel,
  fork,
  call,
} from 'redux-saga/effects';
import Axios from '../../utils/axios';
import actions from './actions';
import teacherActions from './Teacher/actions';
import { rsfFirestore } from '../../utils/firebase';
import InstantQuestionSchema from './schemas';
import teacherSaga from './Teacher/saga';
import makeToast, { toastType } from './InstantQuestionToast';

let syncInstantQuestionTask = null;
let syncStudentAnswersTask = null;

const formatQuestion = question => {
  const { type } = question;
  switch (type) {
    case 'poll':
    case 'multiple': {
      const {
        answers: correctAnswers,
        choices: choicesSet,
        ...rest
      } = question;
      return {
        ...rest,
        questionContent: {
          choicesSet,
          correctAnswers,
        },
      };
    }
    case 'setMarker':
    case 'riskAnalysis':
      return question;
    case 'chart':
      return question;
    case 'matrix':
      return question;
    case 'classify':
      return question;
    case 'fillInBlanks':
      return question;
    default:
      throw new Error('Question Type Not Found.');
  }
};

function* checkPin(params) {
  const { questionId, pin, history } = params;
  try {
    // const { status, data } = yield mockCheckPin(questionId, pin);
    const { status, data } = yield Axios.post(
      `v1/resources/instant_questions/${questionId}/activate`,
      {
        pin,
      }
    );

    if (status === 200) {
      const { token } = data;
      localStorage.setItem('instantToken', token);
      yield put({ type: actions.INSTANT_QUESTION_CHECKPIN_SUCCESS, token });
      yield put({ type: teacherActions.INSTANT_ACTIVATE_QUESTION_SUCCESS });
      // yield put({ type: teacherActions.INSTANT_TEACHER_INIT, questionId });
      history.push(`/instants/${questionId}/teacher`);
    }
  } catch (error) {
    makeToast(toastType.CHECKPIN_FAILED);
    yield put({ type: actions.INSTANT_QUESTION_CHECKPIN_FAILED, error });
  }
}

export function* getQuestion(params) {
  const { instantQuestion } = params;
  const { questionRef } = instantQuestion;

  try {
    const snapshot = yield call(rsfFirestore.getDocument, questionRef);
    const question = formatQuestion(snapshot.data());
    yield put({ type: actions.INSTANT_GET_QUESTION_SUCCESS, question });
  } catch (error) {
    makeToast(toastType.GET_QUESTION_FAILED);
    yield put({ type: actions.INSTANT_GET_QUESTION_FAILED, error });
  }
}

export function* syncStudentAnswers(params) {
  const { questionId } = params;

  if (syncStudentAnswersTask) yield cancel(syncStudentAnswersTask);
  syncStudentAnswersTask = yield fork(
    rsfFirestore.syncDocument,
    `instant_questions/${questionId}/answers/0`,
    {
      transform: snapshot => snapshot.data(),

      successActionCreator: studentAnswers => ({
        type: actions.INSTANT_SYNC_STUDENT_ANSWERS_SUCCESS,
        studentAnswers,
      }),
      failureActionCreator: error => ({
        type: actions.INSTANT_SYNC_STUDENT_ANSWERS_FAILED,
        error,
      }),
    }
  );
}

export function* syncInstantQuestion(params) {
  const { questionId } = params;

  if (syncInstantQuestionTask) {
    yield cancel(syncInstantQuestionTask);
    syncInstantQuestionTask = null;
  } 

  syncInstantQuestionTask = yield fork(
    rsfFirestore.syncDocument,
    `instant_questions/${questionId}`,
    {
      transform: snapshot => {
        const { value, error } = InstantQuestionSchema.validate({
          id: snapshot.get('id'),
          status: snapshot.get('status'),
          questionRef: snapshot.get('question'),
          round: snapshot.get('round'),
          prev: snapshot.get('prev'),
          next: snapshot.get('next'),
          questionNumber: snapshot.get('questionNumber'),
          compareAnswerMethod: snapshot.get('compareAnswerMethod') || null,
        });
        if (error) {
          console.log("error", error)
          throw error};

        return value;
      },
      successActionCreator: instantQuestion => ({
        type: actions.INSTANT_SYNC_QUESTION_SUCCESS,
        instantQuestion,
      }),
      failureActionCreator: error => ({
        type: actions.INSTANT_SYNC_QUESTION_FAILED,
        error,
      }),
    }
  );
}

export function* clearQuestion() {
  if (syncInstantQuestionTask) yield cancel(syncInstantQuestionTask);
  yield put({ type: actions.INSTANT_CLEAR_QUESTION_SUCCESS });
  yield put({ type: teacherActions.INSTANT_CLEAR_QUESTION_TEACHER });
}

export default function* rootSaga() {
  yield all([
    teacherSaga(),
    takeLeading(actions.INSTANT_SYNC_QUESTION, syncInstantQuestion),
    takeLatest(actions.INSTANT_SYNC_QUESTION_SUCCESS, getQuestion),
    takeLatest(actions.INSTANT_QUESTION_CHECKPIN, checkPin),
    takeLatest(actions.INSTANT_CLEAR_QUESTION, clearQuestion),
  ]);
}
