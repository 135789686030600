import {
  all,
  put,
  takeLatest,
  cancel,
  fork,
  select,
  call,
} from 'redux-saga/effects';
import actions from './actions';
import { getQuestions } from '../../Question/saga';
import Axios from '../../../utils/axios';
import { rsfFirestore } from '../../../utils/firebase';
import makeToast, { toastType } from '../InstantQuestionToast';
import jwtDecode from 'jwt-decode';

let syncStudentAnswersTask = null;

export function* syncStudentAnswers(params) {
  const { questionId } = params;

  if (syncStudentAnswersTask) yield cancel(syncStudentAnswersTask);

  syncStudentAnswersTask = yield fork(
    rsfFirestore.syncDocument,
    `instant_questions/${questionId}/answers/0`,
    {
      transform: (snapshot) => snapshot.data(),
      // const { value, error } = StudentAnswersSchema.validate(snapshot.data());

      // if (error) throw error;

      successActionCreator: (studentAnswers) => ({
        type: actions.INSTANT_SYNC_STUDENT_ANSWERS_SUCCESS,
        studentAnswers,
      }),
      failureActionCreator: (error) => ({
        type: actions.INSTANT_SYNC_STUDENT_ANSWERS_FAILED,
        error,
      }),
    }
  );
}

export function* teacherInit(params) {
  const { questionId } = params;
  yield fork(() => syncStudentAnswers({ questionId }));
}

export function* restartQuestion(params) {
  const { questionId } = params;

  try {
    const { status } = yield Axios.put(
      `v1/resources/instant_questions/${questionId}/restart`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('instantToken')}`,
        },
      }
    );

    if (status === 200) {
      yield put({ type: actions.INSTANT_RESTART_QUESTION_SUCCESS });
    }
  } catch (error) {
    yield put({ type: actions.INSTANT_RESTART_QUESTION_FAILED, error });
    makeToast(toastType.RESTART_QUESTION_FAILED);
  }
}

export function* restartAllQuestions(params) {
  const { questionId, next /* , prev */ } = params;

  // get all questions after this question
  const questionIds = [questionId];
  let taskQueue = [questionId];
  if (next && next !== '_') {
    questionIds.push(next);
    taskQueue = [next];
  }
  while (taskQueue.length > 0) {
    console.log('taskQueue', taskQueue);
    const currentId = taskQueue[0];
    console.log('currentId', currentId);
    const doc = yield call(
      rsfFirestore.getDocument,
      `instant_questions/${currentId}`
    );
    const { next } = doc.data();

    if (next && next !== '_') {
      if (!questionIds.includes(next)) {
        questionIds.push(next);
        taskQueue.push(next);
      }
    }
    taskQueue.shift();
  }

  console.log(questionIds);

  const results = [];
  try {
    // eslint-disable-next-line no-restricted-syntax
    for (const id of questionIds) {
      const response = yield Axios.put(
        `v1/resources/instant_questions/${id}/restart`,
        {},
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('instantToken')}`,
          },
        }
      );
      if (response.status === 200) {
        // Reset the 'status' in that question to 'inactive' as well to avoid failure when teacher switches questions
        // Leave the current question untouched
        if (id !== questionId) {
          // console.log(`resetting status of ${id} to 'inactive'...`)
          yield call(
            rsfFirestore.updateDocument,
            `instant_questions/${id}`,
            'status',
            'inactive'
          );
        }
      }
      results.push(response);
    }
    const allSuccess = results.every((res) => res.status === 200);
    if (allSuccess) {
      makeToast(toastType.RESTART_ALL_QUESTIONS_SUCCESSFUL);
      yield put({ type: actions.INSTANT_RESTART_ALL_QUESTIONS_SUCCESS });
    } else {
      throw results;
    }
  } catch (error) {
    makeToast(toastType.RESTART_ALL_QUESTIONS_FAILED);
    console.log('reseted', questionIds.slice(results.length), 'questions');
    yield put({ type: actions.INSTANT_RESTART_QUESTION_FAILED, error });
  }
}

export function* authenticate(params) {
  const { questionId, token } = params;
  try {
    const { status, data } = yield Axios.post(
      `v1/resources/instant_questions/${questionId}/authenticate`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    if (status === 200) {
      localStorage.setItem('teacherName', data.teacherName);
      yield put({ type: actions.INSTANT_AUTHENTICATE_SUCCESS });
      yield put({ type: actions.INSTANT_TEACHER_INIT, questionId });
    }
  } catch (error) {
    yield put({ type: actions.INSTANT_AUTHENTICATE_FAILED, error });
  }
}

export function* activateQuestion(params) {
  const { questionId, token, history } = params;

  try {
    const { status, data } = yield Axios.post(
      `v1/resources/instant_questions/${questionId}/activate`,
      {},
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    if (status === 200) {
      localStorage.setItem('teacherName', data.teacherName);
      yield put({ type: actions.INSTANT_ACTIVATE_QUESTION_SUCCESS });
      yield put({ type: actions.INSTANT_TEACHER_INIT, questionId });
    }
  } catch (error) {
    yield put({ type: actions.INSTANT_ACTIVATE_QUESTION_FAILED, error });
    history.push('/instants');
  }
}

export function* clearQuestionTeacher() {
  if (syncStudentAnswersTask) yield cancel(syncStudentAnswersTask);
  yield put({ type: actions.INSTANT_CLEAR_QUESTION_TEACHER_SUCCESS });
}

export function* finishQuestion(params) {
  const { questionId } = params;

  try {
    const { status } = yield Axios.put(
      `v1/resources/instant_questions/${questionId}/finish`,
      {},
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('instantToken')}`,
        },
      }
    );
    if (status === 200) {
      yield put({ type: actions.INSTANT_FINISH_QUESTION_SUCCESS });
      if (syncStudentAnswersTask) yield cancel(syncStudentAnswersTask);
      // jump tp finish page
    }
  } catch (error) {
    yield put({ type: actions.INSTANT_FINISH_QUESTION_FAILED, error });
  }
}

export function* remoteToggleAnswer() {
  //clear the student.answer
  try {
    const questionTypeList = ['matrix', 'classify', 'fillInBlanks', 'chart'];
    const instantQuestion = yield select((state) => state.InstantQuestion);
    const { id, questionRef, Teacher } = instantQuestion || {};
    const question = yield call(rsfFirestore.getDocument, questionRef);
    const questionType = question.get('type');
    console.log('sagaquestionType', questionType, questionTypeList.indexOf(questionType))
    if (questionTypeList.indexOf(questionType) !== -1) {
      yield call(
        rsfFirestore.updateDocument,
        `instant_questions/${id}`,
        'showAnswer',
        Teacher.answerToggled
      );
    }
  } catch (error) {
    console.log(error);
    makeToast(toastType.REMOTE_TOGGLE_ANSWER_FAILED);
  }
}

export function* getInstantQuestions() {
  try {
    // Fetch questions list

    // Alternatively we can call GET_QUESTIONS action and
    // delegate to the getQuestion saga logic
    // Wait for it to finish
    // yield* getQuestions();
    // const questions = yield select((state) => state.Questions.questions);
    // if (questions.length === 0) // handle the error

    const questionsResponse = yield Axios.get('/v1/resources/questions', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const { status, data } = questionsResponse;

    let questionsData = { questions: []};
    if (data && data.success && data.questions) {
      questionsData = data;
    }

    const { questions } = questionsData;
    const currentUser = jwtDecode(localStorage.getItem('token'));

    const itemToStoreIntoRedux = questions.map((question) => {
      if (question.createdBy) {
        const userRef = Object.values(question.createdBy)[1].segments[1];
        return ({
          ...question,
          editable: userRef && userRef == currentUser.googleUserId,// GET_INSTANT_QUESTIONS_FAIL
          // editable: true, // TODO: remember to uncomment above line
        });
      } else {
        return ({ ...question });
      }
    });

    // Store the questions in to redux as a side-effect
    yield put({
      type: "QUESTION_GET_QUESTION_SUCCESS",
      questions: itemToStoreIntoRedux,
    })

    // Fetch instant question list
    const instantQuestionsSnapshot = yield call(
      rsfFirestore.getCollection,
      'instant_questions'
    );

    // Merge question's information into instant question's
    const instantQuestions = [];

    instantQuestionsSnapshot.forEach((doc) => {
      const insQuestionData = doc.data();
      const questionPath = insQuestionData?.question?.path;
      const userPath = insQuestionData?.user?.path;

      let extraData = questions.find((x) => 'questions/' + x.id === questionPath);

      instantQuestions.push({
        ...insQuestionData,
        id: doc.id,
        user: userPath,
        question: questionPath,
        extraData
      });
    });

    yield put({
      type: actions.GET_INSTANT_QUESTIONS_SUCCESS,
      instantQuestions,
    });
  } catch (error) {
    console.log('getInstantQuestion failed:', error);
    yield put({ type: actions.GET_INSTANT_QUESTIONS_FAILED, error });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.INSTANT_TEACHER_INIT, teacherInit),
    takeLatest(actions.INSTANT_RESTART_QUESTION, restartQuestion),
    takeLatest(actions.INSTANT_RESTART_ALL_QUESTIONS, restartAllQuestions),
    takeLatest(actions.INSTANT_AUTHENTICATE, authenticate),
    takeLatest(actions.INSTANT_ACTIVATE_QUESTION, activateQuestion),
    takeLatest(actions.INSTANT_CLEAR_QUESTION_TEACHER, clearQuestionTeacher),
    takeLatest(actions.INSTANT_FINISH_QUESTION, finishQuestion),
    takeLatest(actions.INSTANT_TOGGLE_ANSWER, remoteToggleAnswer),
    takeLatest(actions.GET_INSTANT_QUESTIONS, getInstantQuestions),
  ]);
}
