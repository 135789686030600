const actions = {
  QUESTION_GET_QUESTIONS: 'QUESTION_GET_QUESTIONS',
  QUESTION_GET_QUESTION_SUCCESS: 'QUESTION_GET_QUESTION_SUCCESS',
  QUESTION_GET_QUESTION_FAILED: 'QUESTION_GET_QUESTION_FAILED',

  QUESTION_SELECT_CURRENT_QUESTION: 'QUESTION_SELECT_CURRENT_QUESTION',

  QUESTION_INIT_QUESTION: 'QUESTION_INIT_QUESTION',

  QUESTION_TOGGLE_VIEW: 'QUESTION_TOGGLE_VIEW',

  QUESTION_EDIT_QUESTION: 'QUESTION_EDIT_QUESTION',

  QUESTION_SAVE_QUESTION: 'QUESTION_SAVE_QUESTION',
  QUESTION_SAVE_QUESTION_SUCCESS: 'QUESTION_SAVE_QUESTION_SUCCESS',
  QUESTION_SAVE_QUESTION_FAILED: 'QUESTION_SAVE_QUESTION_FAILED',

  QUESTION_CANCEL_CREATE_QUESTION: 'QUESTION_CANCEL_CREATE_QUESTION',

  QUESTION_DELETE_QUESTION: 'QUESTION_DELETE_QUESTION',
  QUESTION_DELETE_QUESTION_SUCCESS: 'QUESTION_DELETE_QUESTION_SUCCESS',
  QUESTION_DELETE_QUESTION_FAILED: 'QUESTION_DELETE_QUESTION_FAILED',

  QUESTION_CLEAR_CURRENT_QUESTION: 'QUESTION_CLEAR_CURRENT_QUESTION',

  getQuestions: () => ({
    type: actions.QUESTION_GET_QUESTIONS,
  }),

  selectCurrentQuestion: (questionId) => ({
    type: actions.QUESTION_SELECT_CURRENT_QUESTION,
    questionId,
  }),

  initQuestion: () => ({
    type: actions.QUESTION_INIT_QUESTION,
  }),

  toggleView: (view, history = null, location = null) => {
    console.log('history', history, 'location', location)
    if (history && location) {
      if (view) {
        history.push(`${location.pathname}/edit`);
      } else {
        const parentPath = location.pathname.replace('/edit', '');
        history.replace(parentPath);
      }
    }
    return { type: actions.QUESTION_TOGGLE_VIEW, view };
  },

  editQuestion: (question) => ({
    type: actions.QUESTION_EDIT_QUESTION,
    question,
  }),

  saveQuestion: (isNewQuestion, history) => (dispatch, getState) => {
    const question = getState().Question.editableQuestion;
    dispatch({
      type: actions.QUESTION_SAVE_QUESTION,
      question,
      isNewQuestion,
      history,
    });
  },

  cancelCreateQuestion: () => ({
    type: actions.QUESTION_CANCEL_CREATE_QUESTION,
  }),

  deleteQuestion: (questionId, id) => ({
    type: actions.QUESTION_DELETE_QUESTION,
    questionId,
    id,
  }),

  clearCurrentQuestion: () => ({
    type: actions.QUESTION_CLEAR_CURRENT_QUESTION,
  }),
};

export default actions;
