const actions = {
  STUDENT_CHANGE_NICKNAME: 'STUDENT_CHANGE_NICKNAME',
  STUDENT_CHANGE_SESSIONID: 'STUDENT_CHANGE_SESSIONID',
  STUDENT_TOGGLE_OVERLAY: 'STUDENT_TOGGLE_OVERLAY',

  STUDENT_NICKNAME_VALIDATING: 'STUDENT_NICKNAME_VALIDATING',
  STUDENT_NICKNAME_VALID: 'STUDENT_NICKNAME_VALID',
  STUDENT_NICKNAME_INVALID: 'STUDENT_NICKNAME_INVALID',

  STUDENT_NICKNAME_EMPTY: 'STUDENT_NICKNAME_EMPTY',
  STUDENT_NICKNAME_ERROR: 'STUDENT_NICKNAME_ERROR',

  STUDENT_NICKNAME_REGISTER: 'STUDENT_NICKNAME_REGISTER',
  STUDENT_NICKNAME_REGISTER_SUCCESS: 'STUDENT_NICKNAME_REGISTER_SUCCESS',
  STUDENT_NICKNAME_REGISTER_FAIL: 'STUDENT_NICKNAME_REGISTER_FAIL',
  
  STUDENT_REENTER_LOBBY: 'STUDENT_REENTER_LOBBY',
  STUDENT_SET_SESSION_ID: 'STUDENT_SET_SESSION_ID',
  STUDENT_RESET_QUESTION_TIMER: 'STUDENT_RESET_QUESTION_TIMER',
  STUDENT_SET_QUESTION_TIMEOUT: 'STUDENT_SET_QUESTION_TIMEOUT',
  STUDENT_RESET_ANSWER: 'STUDENT_RESET_ANSWER',
  STUDENT_GET_QUIZ_SESSION_SUCCESS: 'STUDENT_GET_QUIZ_SESSION_SUCCESS',
  STUDENT_GET_QUIZ_SESSION_FAILED: 'STUDENT_GET_QUIZ_SESSION_FAILED',
  STUDENT_GET_QUIZ_SESSION: 'STUDENT_GET_QUIZ_SESSION',
  STUDENT_QUIZ_SESSION_FINISH: 'STUDENT_QUIZ_SESSION_FINISH',

  STUDENT_GET_QUIZ_SESSION_QUESTION: 'STUDENT_GET_QUIZ_SESSION_QUESTION',
  STUDENT_GET_QUIZ_SESSION_QUESTION_SUCCESS:
    'STUDENT_GET_QUIZ_SESSION_QUESTION_SUCCESS',
  STUDENT_GET_QUIZ_SESSION_QUESTION_FAILED:
    'STUDENT_GET_QUIZ_SESSION_QUESTION_FAILED',

  STUDENT_CHOOSE_ANSWER: 'STUDENT_CHOOSE_ANSWER',
  STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE:
    'STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE',
  STUDENT_SUBMIT_ANSWER_POLL: 'STUDENT_SUBMIT_ANSWER_POLL',
  STUDENT_SUBMIT_ANSWER_SET_MARKER: 'STUDENT_SUBMIT_ANSWER_SET_MARKER',
  STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS: 'STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS',

  STUDENT_SYNC_QUIZ_SESSION: 'STUDENT_SYNC_QUIZ_SESSION',

  STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS:
    'STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS',
  STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_SUCCESS:
    'STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_SUCCESS',
  STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_FAILED:
    'STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_FAILED',
  STUDENT_ENTER_QUIZ_SESSION_EMPTY: 'STUDENT_ENTER_QUIZ_SESSION_EMPTY',

  STUDENT_JOIN_QUIZ_SESSION: 'STUDENT_JOIN_QUIZ_SESSION',

  changeStudentNickname: ({ nickname, sessionId }) => ({
    type: actions.STUDENT_CHANGE_NICKNAME,
    nickname,
    sessionId,
  }),

  changeQuizSessionId: ({ sessionId }) => ({
    type: actions.STUDENT_CHANGE_SESSIONID,
    sessionId,
  }),

  registerStudentNickname: ({ nickname, sessionId }) => ({
    type: actions.STUDENT_NICKNAME_REGISTER,
    nickname,
    sessionId,
  }),

  setSessionId: ({ quizSessionId }) => ({
    type: actions.STUDENT_SET_SESSION_ID,
    quizSessionId: quizSessionId.toLowerCase(),
  }),

  toggleOverlay: () => ({
    type: actions.STUDENT_TOGGLE_OVERLAY,
  }),

  getQuizSession: ({ sessionId }) => ({
    type: actions.STUDENT_GET_QUIZ_SESSION,
    sessionId,
  }),
  getQuizSessionQuestion: ({ sessionId, questionNumber, nickname }) => ({
    type: actions.STUDENT_GET_QUIZ_SESSION_QUESTION,
    sessionId,
    questionNumber,
    nickname,
  }),
  chooseAnswer: ({ answer }) => ({
    type: actions.STUDENT_CHOOSE_ANSWER,
    answer,
  }),
  submitAnswerMultipleChoice: ({ answer }) => ({
    type: actions.STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE,
    answer,
  }),
  submitAnswerPoll: ({ answer }) => ({
    type: actions.STUDENT_SUBMIT_ANSWER_POLL,
    answer,
  }),
  submitAnswerSetMarker: answer => ({
    type: actions.STUDENT_SUBMIT_ANSWER_SET_MARKER,
    answer,
  }),
  submitAnswerRiskAnalysis: answer => ({
    type: actions.STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS,
    answer,
  }),
  resetQuestionTimer: () => dispatch => {
    dispatch({
      type: actions.STUDENT_RESET_QUESTION_TIMER,
    });
    dispatch({
      type: actions.STUDENT_RESET_ANSWER,
    });
  },
  setQuestionTimeout: () => ({
    type: actions.STUDENT_SET_QUESTION_TIMEOUT,
  }),
  reenterLobby: ({ sessionId, nickname }) => ({
    type: actions.STUDENT_REENTER_LOBBY,
    sessionId,
    nickname,
  }),
  resetAnswer: () => ({
    type: actions.STUDENT_RESET_ANSWER,
  }),
  quizSessionFinish: () => ({
    type: actions.STUDENT_QUIZ_SESSION_FINISH,
  }),
  checkQuizSessionActivationStatus: sessionId => ({
    type: actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS,
    sessionId,
  }),
  checkQuizSessionActivationStatusSuccess: () => ({
    type: actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_SUCCESS,
  }),
  checkQuizSessionActivationStatusFailed: () => ({
    type: actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_FAILED,
  }),
  joinQuizSession: sessionId => ({
    type: actions.STUDENT_JOIN_QUIZ_SESSION,
    sessionId,
  }),
};

export default actions;
