import actions from './actions';

export default function subjectReducer(
  state = {
    subjects: [],
    subjectQuizzes: [],
    currentPath: [],
    isFetching: false,
    isFetchingQuizzes: false,
    page: 1,
    per_page: 100,
  },
  { type, ...action }
) {
  switch (type) {
    case actions.GET_SUBJECTS:
      return {
        ...state,
        isFetching: true,
        page: action.page,
        per_page: action.per_page,
      };
    case actions.GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        subjects: action.subjects,
      };
    case actions.GET_SUBJECTS_FAILED:
      return {
        ...state,
        isFetching: false,
      };

    case actions.GET_SUBJECT_QUIZZES:
      return {
        ...state,
        isFetchingQuizzes: false,
      };
    case actions.GET_SUBJECT_QUIZZES_SUCCESS:
      return {
        ...state,
        isFetchingQuizzes: false,
        subjectQuizzes: action.subjectQuizzes,
      };
    case actions.GET_SUBJECT_QUIZZES_FAILED:
      return {
        ...state,
        isFetchingQuizzes: false,
      };
    case actions.SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: action.currentPath,
      };
    default:
      return state;
  }
}
