import { toast } from 'react-toastify';

export const toastType = {
  SAVE_QUESTION_FAILED: 'SAVE_QUESTION_FAILED',
};

export default type => {
  switch (type) {
    case toastType.CREATE_QUESTION_FAILED:
      toast.error('Save Question Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;

    default:
      toast.error(`Error ${type}`, {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
  }
};
