import { toast } from 'react-toastify';

export const toastType = {
  ACTIVATE_QUIZ_SESSION_FAILED: 'ACTIVATE_QUIZ_SESSION_FAILED',
  SYNC_QUIZ_SESSION_FAILED: 'SYNC_QUIZ_SESSION_FAILED',
  SYNC_STUDENT_ANSWERS_FAILED: 'SYNC_STUDENT_ANSWERS_FAILED',
  SYNC_STUDENT_LOADED_FAILED: 'SYNC_STUDENT_LOADED_FAILED',
  SYNC_STUDENT_ENTERED_FAILED: 'SYNC_STUDENT_ENTERED_FAILED',
  GET_QUIZ_SESSION_QUESTION_FAILED: 'GET_QUIZ_SESSION_QUESTION_FAILED',
  LOAD_QUESTION_FAILED: 'LOAD_QUESTION_FAILED',
  START_QUESTION_FAILED: 'START_QUESTION_FAILED',
  FINISH_QUIZ_SESSION_FAILED: 'FINISH_QUIZ_SESSION_FAILED',
};

export default type => {
  switch (type) {
    case toastType.GET_QUIZ_SESSION_QUESTION_FAILED:
      toast.error('😊 GET Quiz Question Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.LOAD_QUESTION_FAILED:
      toast.error('😊 Load Question Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.ACTIVATE_QUIZ_SESSION_FAILED:
      toast.error('🤗 Activate Question Session Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.START_QUESTION_FAILED:
      toast.error('😃 Start Question Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.FINISH_QUIZ_SESSION_FAILED:
      toast.error('😝 Finish Question Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.SYNC_QUIZ_SESSION_FAILED:
      toast.error('😝 Sync Quiz Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.SYNC_STUDENT_ANSWERS_FAILED:
      toast.error('😝 Sync Student Answers Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.SYNC_STUDENT_LOADED_FAILED:
      toast.error('😝 Sync Student Loaded Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.SYNC_STUDENT_ENTERED_FAILED:
      toast.error('😝 Sync Student Entered Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    default:
      toast.error('😊 Unexpected Error Please Refresh', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
  }
};
