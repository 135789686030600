/* eslint-disable react/jsx-wrap-multilines */
import React, { lazy, Suspense, useEffect } from 'react';
import Loader from 'react-loaders';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { hideTawkTo } from '../../../utils/tawkToHelper';

const devRoute = () => (
  <>
    <Suspense
      fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="line-scale-party" />
            </div>
            <h6 className="mt-3">
              Please wait while we load all the Pages examples
              <small>
                Because this is a demonstration we load at once all the Pages
                examples. This wouldn't happen in a real live app!
              </small>
            </h6>
          </div>
        </div>
      }
    >
      <Switch>
        {/* Auth */}
        <Route
          path="/auth"
          component={lazy(() => import('../../../Pages/UserPage'))}
        />

        {/* Pages */}
        <Route path="/pages" component={lazy(() => import('../../../Pages'))} />

        {/* Student */}
        {/* <Route
          path="/students"
          component={lazy(() => import('../../../Pages/Student'))}
        /> */}

        {/* Instant */}
        <Route
          path="/instants"
          component={lazy(() => import('../../../Pages/InstantQuestion'))}
        />
        {/* Home page */}
        <Route
          exact
          path="/"
          component={lazy(() => import('../../../Pages/Home'))}
        />
        {/* <Redirect to="/instants" /> */}
      </Switch>
    </Suspense>
    <ToastContainer />
  </>
);

const AppMain = () => {
  useEffect(() => {
    hideTawkTo();
  }, []);
  return devRoute();
};

export default AppMain;
