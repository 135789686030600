import setMarkerActions from './SetMarker/actions';
import riskAnalysisActions from './RiskAnalysis/actions';

const actions = {
  GET_QUIZ_SESSION: 'GET_QUIZ_SESSION',
  GET_QUIZ_SESSION_SUCCESS: 'GET_QUIZ_SESSION_SUCCESS',
  GET_QUIZ_SESSION_FAILED: 'GET_QUIZ_SESSION_FAILED',

  GET_QUIZ_SESSION_QUESTION: 'GET_QUIZ_SESSION_QUESTION',
  GET_QUIZ_SESSION_QUESTION_SUCCESS: 'GET_QUIZ_SESSION_QUESTION_SUCCESS',
  GET_QUIZ_SESSION_QUESTION_FAILED: 'GET_QUIZ_SESSION_QUESTION_FAILED',

  ACTIVATE_QUIZ_SESSION: 'ACTIVATE_QUIZ_SESSION',
  ACTIVATE_QUIZ_SESSION_SUCCESS: 'ACTIVATE_QUIZ_SESSION_SUCCESS',
  ACTIVATE_QUIZ_SESSION_FAILED: 'ACTIVATE_QUIZ_SESSION_FAILED',

  LOAD_QUESTION: 'LOAD_QUESTION',
  LOAD_QUESTION_SUCCESS: 'LOAD_QUESTION_SUCCESS',
  LOAD_QUESTION_FAILED: 'LOAD_QUESTION_FAILED',

  START_QUESTION: 'START_QUESTION',
  START_QUESTION_SUCCESS: 'START_QUESTION_SUCCESS',
  START_QUESTION_FAILED: 'START_QUESTION_FAILED',

  SYNC_STUDENT_LOADED: 'SYNC_STUDENT_LOADED',
  SYNC_STUDENT_LOADED_FAILED: 'SYNC_STUDENT_LOADED_FAILED',

  SYNC_STUDENT_ENTERED: 'SYNC_STUDENT_ENTERED',
  SYNC_STUDENT_ENTERED_FAILED: 'SYNC_STUDENT_ENTERED_FAILED',

  SYNC_STUDENT_ANSWERS: 'SYNC_STUDENT_ANSWERS',
  SYNC_STUDENT_ANSWERS_FAILED: 'SYNC_STUDENT_ANSWERS_FAILED',

  SYNC_QUIZ_SESSION: 'SYNC_QUIZ_SESSION',
  SYNC_QUIZ_SESSION_FAILED: 'SYNC_QUIZ_SESSION_FAILED',

  FINISH_QUIZ_SESSION: 'FINISH_QUIZ_SESSION',
  FINISH_QUIZ_SESSION_SUCCESS: 'FINISH_QUIZ_SESSION_SUCCESS',
  FINISH_QUIZ_SESSION_FAILED: 'FINISH_QUIZ_SESSION_FAILED',

  TOGGLE_ANSWER: 'TOGGLE_ANSWER',

  CLEAR_QUIZ_SESSION: 'CLEAR_QUIZ_SESSION',

  CHANGE_STEP: 'CHANGE_STEP',
  CHANGE_STEP_SUCCESS: 'CHANGE_STEP_SUCCESS',
  CHANGE_STEP_FAILED: 'CHANGE_STEP_FAILED',

  RESET_QUIZ_SESSION: 'RESET_QUIZ_SESSION',
  RESET_QUIZ_SESSION_SUCCESS: 'RESET_QUIZ_SESSION_SUCCESS',
  RESET_QUIZ_SESSION_FAILED: 'RESET_QUIZ_SESSION_FAILED',

  RESET_SINGLE_QUESTION: 'RESET_SINGLE_QUESTION',
  RESET_SINGLE_QUESTION_SUCCESS: 'RESET_SINGLE_QUESTION_SUCCESS',
  RESET_SINGLE_QUESTION_FAILED: 'RESET_SINGLE_QUESTION_FAILED',

  getQuizSession: ({ sessionId }) => ({
    type: actions.GET_QUIZ_SESSION,
    sessionId,
  }),

  loadQuestion: ({ sessionId, questionNumber }) => ({
    type: actions.LOAD_QUESTION,
    sessionId,
    questionNumber,
  }),

  startQuestion: ({ sessionId, questionNumber }) => ({
    type: actions.START_QUESTION,
    sessionId,
    questionNumber,
  }),

  activateQuizSession: ({ sessionId, history }) => ({
    type: actions.ACTIVATE_QUIZ_SESSION,
    sessionId,
    history,
  }),

  toggleAnswer: () => ({
    type: actions.TOGGLE_ANSWER,
  }),

  getQuizSessionQuestion: ({ sessionId, questionNumber }) => ({
    type: actions.GET_QUIZ_SESSION_QUESTION,
    sessionId,
    questionNumber,
  }),

  finishQuizSession: ({ sessionId }) => ({
    type: actions.FINISH_QUIZ_SESSION,
    sessionId,
  }),

  clearQuizSession: () => ({
    type: actions.CLEAR_QUIZ_SESSION,
  }),

  changeStep: ({ sessionId, stepNumber }) => ({
    type: actions.CHANGE_STEP,
    sessionId,
    stepNumber,
  }),

  resetQuizSession: ({ sessionId }) => ({
    type: actions.RESET_QUIZ_SESSION,
    sessionId,
  }),

  resetSingleQuestion: ({ sessionId, currentQuestionNumber }) => ({
    type: actions.RESET_SINGLE_QUESTION,
    sessionId,
    currentQuestionNumber,
  }),

  // actions for set marker questions
  ...setMarkerActions,
  // actions for risk analysis questions, extends from set marker questions
  ...riskAnalysisActions,
};

export default actions;
