import actions from './actions';
import TeacherReducer, { teacherInitState } from './Teacher/reducer';

const initState = {
  id: null,
  question: null,
  questionRef: null,
  token: null,
  status: null,
  round: null,
  questionNumber: 0,
  isChecking: false,
  isLoadingQuestion: false,
  Teacher: teacherInitState,
};

function instantQuestionReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.INSTANT_QUESTION_CHECKPIN:
      return {
        ...state,
        isChecking: true,
      };
    case actions.INSTANT_QUESTION_CHECKPIN_SUCCESS:
      return {
        ...state,
        isChecking: false,
        token: action.token,
      };
    case actions.INSTANT_QUESTION_CHECKPIN_FAILED:
      return {
        ...state,
        isChecking: false,
      };
    case actions.INSTANT_SYNC_QUESTION:
      return {
        ...state,
        isLoadingQuestion: true,
      };
    case actions.INSTANT_SYNC_QUESTION_SUCCESS:
      return {
        ...state,
        ...action.instantQuestion,
        Teacher: teacherInitState,
      };
    case actions.INSTANT_SYNC_QUESTION_FAILED:
      return {
        ...state,
        isLoadingQuestion: false,
      };
    case actions.INSTANT_GET_QUESTION_SUCCESS:
      return {
        ...state,
        question: action.question,
        isLoadingQuestion: false,
      };
    case actions.INSTANT_GET_QUESTION_FAILED:
      return {
        ...state,
        isLoadingQuestion: false,
      };
    case actions.INSTANT_CLEAR_QUESTION_SUCCESS:
      return initState;
    default:
      return state;
  }
}

export default (state = initState, { type, ...action }) => {
  const { Teacher, Student, ...InstantQuestion } = state;
  return {
    ...instantQuestionReducer(InstantQuestion, { type, ...action }),
    // Import reducer for teacher and student
    Teacher: TeacherReducer(Teacher, { type, ...action }),
  };
};
