// extends from the actions of SetMarker
const actions = {
  TOGGLE_ANALYSIS: 'TOGGLE_ANALYSIS',
  SYNC_STUDENT_ANSWERS_RISK_ANALYSIS: 'SYNC_STUDENT_ANSWERS_RISK_ANALYSIS',

  toggleAnalysis: () => ({
    type: actions.TOGGLE_ANALYSIS,
  }),
  syncStudentAnswersRiskAnalysis: (studentAnswers) => ({
    type: actions.SYNC_STUDENT_ANSWERS_RISK_ANALYSIS,
    studentAnswers,
  }),
};

export default actions;
