import { all, put, takeLatest } from 'redux-saga/effects';
import Axios from '../../utils/axios';
import makeToast, { toastType } from './QuestionToast';
import actions from './actions';

function* getQuizzes() {
  try {
    const response = yield Axios.get('/v1/resources/quiz_sessions', {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const { status, data } = response;
    if (status === 200 && data) {
      yield put({
        type: actions.QUIZ_GET_QUIZZES_SUCCESS,
        quizzes: data.map(d => ({ ...d, id: d.shareCode })),
      });
    } else {
      yield put({
        type: actions.QUIZ_GET_QUIZZES_FAILED,
      });
    }
  } catch (err) {
    yield put({
      type: actions.QUIZ_GET_QUIZZES_FAILED,
    });
  }
}

function* getQuizQuestions(params) {
  const { quizId } = params;

  try {
    const response = yield Axios.get(
      `/v1/resources/quiz_sessions/${quizId}/questions`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    const { status, data } = response;
    if (status === 200 && data) {
      yield put({
        type: actions.QUIZ_GET_QUIZ_QUESTIONS_SUCCESS,
        quizQuestions: data,
      });
    } else {
      makeToast(toastType.GET_QUIZ_QUESTIONS_FAILED);
      yield put({
        type: actions.QUIZ_GET_QUIZ_QUESTIONS_FAILED,
      });
    }
  } catch (err) {
    makeToast(toastType.GET_QUIZ_QUESTIONS_FAILED);
    yield put({
      type: actions.QUIZ_GET_QUIZ_QUESTIONS_FAILED,
    });
  }
}

function* editQuizQuestions(params) {
  const { quizId, quizQuestions } = params;

  try {
    const response = yield Axios.post(
      `/v1/resources/quiz_sessions/${quizId}/questions`,
      quizQuestions,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    const { status, data } = response;
    if (status === 201 && data) {
      yield put({
        type: actions.QUIZ_EDIT_QUIZ_QUESTIONS_SUCCESS,
        quizQuestions: data,
      });
    } else {
      makeToast(toastType.EDIT_QUIZ_QUESTIONS_FAILED);
      yield put({
        type: actions.QUIZ_EDIT_QUIZ_QUESTIONS_FAILED,
      });
    }
  } catch (err) {
    makeToast(toastType.EDIT_QUIZ_QUESTIONS_FAILED);
    yield put({
      type: actions.QUIZ_EDIT_QUIZ_QUESTIONS_FAILED,
    });
  }
}

function* saveQuiz(params) {
  const { quiz, isNewQuiz } = params;
  const { id } = quiz;

  try {
    let res = null;
    if (isNewQuiz) {
      res = yield Axios.post('/v1/resources/quiz_sessions/', quiz, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else {
      res = yield Axios.put(`/v1/resources/quiz_sessions/${id}`, quiz, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    }

    const { status, data } = res;
    if (status === 200 && data) {
      yield put({
        type: actions.QUIZ_SAVE_QUIZ_SUCCESS,
        quiz: data
      });
    } else {
      makeToast(toastType.SAVE_QUIZ_FAILED);
      yield put({
        type: actions.QUIZ_SAVE_QUIZ_FAILED,
      });
    }
  } catch (err) {
    makeToast(toastType.SAVE_QUIZ_FAILED);
    yield put({
      type: actions.QUIZ_SAVE_QUIZ_FAILED,
    });
  }
}

function* deleteQuiz(params) {
  const { quizId } = params;

  try {
    const response = yield Axios.delete(
      `/v1/resources/quiz_sessions/${quizId}`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    const { status } = response;

    if (status !== 204) throw new Error('Delete Failed with code ', status);

    makeToast(toastType.DELETE_QUIZ_SUCCESS);
    yield put({ type: actions.QUIZ_DELETE_QUIZ_SUCCESS, quizId });
  } catch (err) {
    makeToast(toastType.DELETE_QUIZ_FAILED);
    yield put({ type: actions.QUIZ_DELETE_QUIZ_FAILED });
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(actions.QUIZ_GET_QUIZZES, getQuizzes),
    takeLatest(actions.QUIZ_GET_QUIZ_QUESTIONS, getQuizQuestions),
    takeLatest(actions.QUIZ_EDIT_QUIZ_QUESTIONS, editQuizQuestions),
    takeLatest(actions.QUIZ_SAVE_QUIZ, saveQuiz),
    takeLatest(actions.QUIZ_DELETE_QUIZ, deleteQuiz),
  ]);
}
