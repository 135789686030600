import actions from './actions';

export const initState = {
  // overlay: null,
  userType: null, // Enum('teacher', 'student')

  // Teacher
  showHeatmap: false,
  comments: [],
  correctAnswers: {},
};

function combineAnswers(allAnswersObject, newAnswer) {
  const { length } = Object.keys(allAnswersObject);
  const keyName = `${length + 1}`;
  const newAnswerObject = {};
  newAnswerObject[keyName] = newAnswer;

  return { ...allAnswersObject, ...newAnswerObject };
}

export default function setMarkerReducer(
  state = initState,
  { type, ...action }
) {
  switch (type) {
    case actions.TOGGLE_HEATMAP:
      return {
        ...state,
        showHeatmap: !state.showHeatmap,
      };
    case actions.TOGGLE_CORRECT_ANSWERS:
      return {
        ...state,
        showCorrectAnswers: !state.showCorrectAnswers,
      };
    case actions.TEACHER_ADD_ANSWER:
      return {
        ...state,
        correctAnswers: combineAnswers(state.correctAnswers, action.answer),
      };
    default:
      return state;
  }
}
