import Joi from '@hapi/joi';

export default Joi.object({
  id: Joi.string().required(),
  questionRef: Joi.object().required(),
  status: Joi.string().required(),
  round: Joi.number(),
  next: Joi.string(),
  prev: Joi.string(),
  questionNumber: Joi.number(),
  // The answer can be compared in various ways, including 'byPosition',
  // which just compare the answers's position, another is the relative relationships
  compareAnswerMethod: Joi.string()
    .allow('byPosition', 'byHierarchy', null)
    .optional(),
});
