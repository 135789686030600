import { all, put, takeLatest } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import Axios, { serverUrl } from '../../utils/axios';

import actions from './actions';

function* tradeForToken(params) {
  const { googleToken, history } = params;
  try {
    const questionsResponse = yield Axios.get(
      `${serverUrl}/v1/utils/login/token/`,
      {
        headers: {
          authorization: `Bearer ${googleToken}`,
        },
      }
    );

    const { status, data } = questionsResponse;
    if (status === 200 && data && data.status === 'Success') {
      localStorage.setItem('token', data.token);
      yield put({
        type: actions.AUTH_TRADE_FOR_TOKEN_SUCCESS,
        token: data.token,
        user: jwtDecode(data.token),
      });
      history.push('/pages');
    } else {
      yield put({
        type: actions.AUTH_TRADE_FOR_TOKEN_FAILED,
        err:
          'Firebase ID token has invalid signature. See https://firebase.google.com/docs/auth/admin/verify-id-tokens for details on how to retrieve an ID token.',
      });
    }
  } catch (err) {
    yield put({
      type: actions.AUTH_TRADE_FOR_TOKEN_FAILED,
      err:
        (err.response && err.response.data && err.response.data.info) ||
        'Firebase ID token has invalid signature. See https://firebase.google.com/docs/auth/admin/verify-id-tokens for details on how to retrieve an ID token.',
    });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.AUTH_TRADE_FOR_TOKEN, tradeForToken)]);
}
