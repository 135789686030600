import QuizSession from './QuizSession/reducer';
import Question from './Question/reducer';
import InstantQuestion from './InstantQuestion/reducer';
import InstantQuestionStudent from './InstantQuestionStudent/reducer';
import Quiz from './Quiz/reducer';
import Auth from './Auth/reducer';
import Subject from './Subject/reducer';
import Student from './Student/reducer';
import ThemeOptions from './ThemeOptions/reducer';

export default {
  ThemeOptions,
  Subject,
  Auth,
  Question,
  InstantQuestion,
  InstantQuestionStudent,
  QuizSession,
  Quiz,
  Student,
};
