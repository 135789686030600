import { toast } from 'react-toastify';

export const toastType = {
  GET_QUIZ_QUESTIONS_FAILED: 'GET_QUIZ_QUESTIONS_FAILED',
  DELETE_QUIZ_FAILED: 'DELETE_QUIZ_FAILED',
  DELETE_QUIZ_SUCCESS: 'DELETE_QUIZ_SUCCESS',
};

export default type => {
  switch (type) {
    case toastType.GET_QUIZ_QUESTIONS_FAILED:
      toast.error('😊 Get Quiz Questions Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;

    case toastType.EDIT_QUIZ_QUESTIONS_FAILED:
      toast.error('🤪 Edit Quiz Questions Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.DELETE_QUIZ_SUCCESS:
      toast.error('🤪 Delete Quiz Success', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;
    case toastType.DELETE_QUIZ_FAILED:
      toast.error('🤪 Delete Quiz Failed', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      break;

    default:
      toast.error('😊 Nothing To Show', {
        toastId: 'nonActive',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
  }
};
