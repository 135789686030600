import { toast } from 'react-toastify';
import { all, call, debounce, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import Axios, { serverUrl } from '../../utils/axios';
import { firestore, rsfFirestore } from '../../utils/firebase';
import actions from './actions';

let quizSessionChannel = null;

export function* syncQuizSession(params) {
  const { sessionId } = params;

  if (quizSessionChannel) quizSessionChannel.close();

  quizSessionChannel = rsfFirestore.channel(
    `quiz_sessions/${sessionId.toLowerCase()}`,
    'document'
  );

  while (true) {
    try {
      const quizSession = yield take(quizSessionChannel);
      yield put(actions.resetQuestionTimer());
      yield put({
        type: actions.STUDENT_SYNC_QUIZ_SESSION,
        quizSession: quizSession.data(),
      });
      const { status } = quizSession.data();
      if (status === 'finish') {
        quizSessionChannel.close();
        break;
      }
    } catch (error) {
      console.log(error);
      yield put({
        type: actions.STUDENT_QUIZ_SESSION_FINISH,
      });
    }
  }
}

export function* checkNickname(params) {
  const { nickname, sessionId } = params;
  if (nickname === '') {
    yield put({
      type: actions.STUDENT_NICKNAME_EMPTY,
    });
  } else {
    try {
      yield put({ type: actions.STUDENT_NICKNAME_VALIDATING });
      const snapshot = yield call(
        rsfFirestore.getDocument,
        `quiz_sessions/${sessionId}/student_entered/0`
      );
      const nicknames = snapshot.data();

      if (Object.keys(nicknames).includes(nickname)) {
        console.log('invalid');
        yield put({
          type: actions.STUDENT_NICKNAME_INVALID,
        });
      } else {
        console.log('valid');
        yield put({
          type: actions.STUDENT_NICKNAME_VALID,
        });
      }
    } catch (err) {
      toast.error('😔 The quiz session seems not started yet', {
        toastId: 'nonActive',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      yield put({
        type: actions.STUDENT_GET_QUIZ_SESSION_FAILED,
        err: err.message,
      });
    }
  }
}

export function* studentRegister(params) {
  const { sessionId, nickname } = params;
  try {
    const docRef = firestore.doc(
      `quiz_sessions/${sessionId}/student_entered/0`
    );
    yield firestore.runTransaction(t => t.get(docRef).then(doc => {
      if (!Object.keys(doc.data()).includes(nickname)) {
        t.update(docRef, { [nickname]: true });
      } else {
        throw new Error('400');
      }
    }));
    console.log('Successfully Registered');
    yield put({
      type: actions.STUDENT_NICKNAME_REGISTER_SUCCESS,
    });
  } catch (err) {
    console.log('err', err);
    if (err.message === '400') {
      yield put({
        type: actions.STUDENT_NICKNAME_INVALID,
      });
    }
    yield put({
      type: actions.STUDENT_NICKNAME_REGISTER_FAIL,
    });
  }
}

export function* getQuizSession(params) {
  const { sessionId } = params;
  // idToken is not required for students
  // const idToken = yield FirebaseHelper.getCurrentUser();

  try {
    const quizSession = yield Axios.get(
      `${serverUrl}/v1/resources/quiz_sessions/${sessionId.toLowerCase()}/`
      // {
      //   headers: { authorization: `Bearer ${idToken}` },
      // }
    );

    if (quizSession.status === 200) {
      yield fork(() => syncQuizSession({ sessionId }));

      yield put({
        type: actions.STUDENT_GET_QUIZ_SESSION_SUCCESS,
        quizSession: {
          ...quizSession.data,
        },
      });
    } else {
      yield put({
        type: actions.STUDENT_GET_QUIZ_SESSION_FAILED,
        err: 'GET QUIZ SESSION FAILED',
      });
    }
  } catch (err) {
    console.log('err', err.response);

    yield put({
      type: actions.STUDENT_GET_QUIZ_SESSION_FAILED,
      err: err.message,
    });
  }
}

function* startLoadQuestion(params) {
  const { sessionId, questionNumber, nickname } = params;

  const data = {};
  data[nickname] = true;
  yield rsfFirestore.setDocument(
    `quiz_sessions/${sessionId.toLowerCase()}/student_entered/${questionNumber}`,
    data,
    { merge: true }
  );
}

export function* reenterLobby(params) {
  const { sessionId, nickname } = params;
  const data = {};
  data[nickname] = true;
  console.log('here');
  yield rsfFirestore.setDocument(
    `quiz_sessions/${sessionId.toLowerCase()}/student_entered/0`,
    data,
    { merge: true }
  );
}

function* finishLoadQuestion(params) {
  const { sessionId, questionNumber, nickname } = params;

  const data = {};
  data[nickname] = true;
  yield rsfFirestore.setDocument(
    `quiz_sessions/${sessionId.toLowerCase()}/student_loaded/${questionNumber}`,
    data,
    { merge: true }
  );
}

export function* getQuizSessionQuestion(params) {
  const { sessionId, questionNumber } = params;

  yield fork(() => startLoadQuestion(params));
  try {
    const snapshot = yield call(
      rsfFirestore.getDocument,
      `quiz_sessions/${sessionId}/questions/${questionNumber}`
    );
    yield fork(() => finishLoadQuestion(params));
    const { choices, ...rest } = snapshot.data();
    yield put({
      type: actions.STUDENT_GET_QUIZ_SESSION_QUESTION_SUCCESS,
      question: {
        questionContent: {
          correctAnswers: null,
          choicesSet: choices,
        },
        ...rest,
      },
    });
  } catch (err) {
    yield put({
      type: actions.STUDENT_GET_QUIZ_SESSION_QUESTION_FAILED,
      err: err.message,
    });
  }
}

export function* submitAnswerMulipleChoice(params) {
  const { answer } = params;
  const { Student } = yield select();
  const { quizSession } = Student;
  const { shareCode, progress } = quizSession;
  const { currentQuestionNumber } = progress;
  const { nickname } = Student;

  const data = {};
  console.log(answer);
  data[answer] = { [nickname]: true };
  try {
    toast.info('Submitting Answer......', {
      toastId: 'submitting',
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
    yield rsfFirestore.setDocument(
      `quiz_sessions/${shareCode.toLowerCase()}/student_answers/${currentQuestionNumber}`,
      data,
      { merge: true }
    );
    toast.update('submitting', {
      render: 'Answer Submitted Successfully',
      type: toast.TYPE.SUCCESS,
    });
  } catch (error) {
    toast.error(
      '😔 Something Wrong when submitting the Answer, please try agin',
      {
        toastId: 'nonActive',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }
}

export function* submitAnswerPoll(params) {
  const { answer } = params;
  const { Student } = yield select();
  const { quizSession } = Student;
  const { shareCode, progress } = quizSession;
  const { currentQuestionNumber } = progress;
  const { nickname } = Student;

  const data = {};
  console.log(answer);
  answer.forEach(option => {
    data[option] = { [nickname]: true };
  });
  try {
    toast.info('Submitting Answer......', {
      toastId: 'submitting',
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
    });
    yield rsfFirestore.setDocument(
      `quiz_sessions/${shareCode.toLowerCase()}/student_answers/${currentQuestionNumber}`,
      data,
      { merge: true }
    );
    toast.update('submitting', {
      render: 'Answer Submitted Successfully',
      type: toast.TYPE.SUCCESS,
    });
  } catch (error) {
    toast.error(
      '😔 Something Wrong when submitting the Answer, please try agin',
      {
        toastId: 'nonActive',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }
}

function* submitAnswer(
  params,
  defaultParams = { sessionId: '', questionNumber: 1 }
) {
  const { data } = params;
  let { sessionId, questionNumber } = params;

  if (!sessionId) {
    sessionId = defaultParams.sessionId;
  }

  if (!questionNumber || questionNumber <= 0) {
    // TODO: should throw error in production environment
    questionNumber = defaultParams.questionNumber;
  }

  const path = `quiz_sessions/${sessionId.toLowerCase()}/student_answers/${questionNumber}`;
  yield rsfFirestore.setDocument(path, data, { merge: true });
}

function generateNameIfInvalid(nickname, length = 6) {
  if (!(nickname === '' || nickname === undefined)) {
    return nickname;
  }
  const randomStr = Math.random()
    .toString(36)
    .substr(2);
  const maxLen = randomStr.length;
  return length > maxLen ? randomStr : randomStr.slice(0, length);
}

// TODO: avoid using select
export function* submitAnswerSetMarker(answer) {
  const { Student } = yield select();
  const { quizSession } = Student;
  let { nickname } = Student;
  const { shareCode, progress } = quizSession;
  const { currentQuestionNumber } = progress;

  const data = {};
  // TODO: remove before deployed to production
  nickname = generateNameIfInvalid(nickname);
  data[nickname] = { ...answer.answer };

  const params = {
    data,
    sessionId: shareCode,
    questionNumber: currentQuestionNumber,
  };
  yield* submitAnswer(params);
}

/**
 * Submit answer for risk analysis question
 * @param {object} parameters Parameters of the function
 *    Structure: { sessionId: string, nickname, questionNumber: number|string, answer: {  } }
 */
export function* submitAnswerRiskAnalysis(params) {
  console.log(params.answer);
  const { sessionId, questionNumber, nickname, answer: rating } = params.answer;

  const data = {};
  data[nickname] = rating;

  console.log(data);

  const newParams = { data, sessionId, questionNumber };
  yield* submitAnswer(newParams);
}

/**
 * Check Quiz Session ActivationStatus
 * @param {object} parameters Parameters of the function
 *    Structure: { sessionId: string, nickname, questionNumber: number|string, answer: {  } }
 */
export function* checkQuizSessionActivationStatus(params) {
  const { sessionId } = params;
  if (sessionId === '' || sessionId.length !== 6) {
    yield put({
      type: actions.STUDENT_ENTER_QUIZ_SESSION_EMPTY,
    });
  } else {
    try {
      yield put({ type: actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS });
      const documentSnapshot = yield call(
        rsfFirestore.getDocument,
        `quiz_sessions/${sessionId}`
      );
      if (!documentSnapshot.exists) {
        console.log('Invalid Quiz Session Id');
        yield put({
          type: actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_FAILED,
        });
      } else {
        console.log('Valid Quiz Session Id');
        yield put({
          type: actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_SUCCESS,
        });
      }
    } catch (err) {
      toast.error(err, {
        toastId: 'nonActive',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
      yield put({
        type: actions.STUDENT_CHECK_QUIZ_SESSION_ACTIVATION_STATUS_FAILED,
      });
    }
  }
}

export default function* rootSaga() {
  yield all([
    debounce(500, actions.STUDENT_CHANGE_NICKNAME, checkNickname),
    takeLatest(actions.STUDENT_REENTER_LOBBY, reenterLobby),
    takeLatest(actions.STUDENT_NICKNAME_REGISTER, studentRegister),
    takeLatest(actions.STUDENT_GET_QUIZ_SESSION, getQuizSession),
    takeLatest(
      actions.STUDENT_GET_QUIZ_SESSION_QUESTION,
      getQuizSessionQuestion
    ),
    takeLatest(actions.STUDENT_SUBMIT_ANSWER_SET_MARKER, submitAnswerSetMarker),
    takeLatest(
      actions.STUDENT_SUBMIT_ANSWER_MULTIPLE_CHOICE,
      submitAnswerMulipleChoice
    ),
    takeLatest(actions.STUDENT_SUBMIT_ANSWER_POLL, submitAnswerPoll),
    takeLatest(
      actions.STUDENT_SUBMIT_ANSWER_RISK_ANALYSIS,
      submitAnswerRiskAnalysis
    ),
    debounce(
      500,
      actions.STUDENT_CHANGE_SESSIONID,
      checkQuizSessionActivationStatus
    ),
  ]);
}
