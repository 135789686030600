export const firebaseConfig = {
  apiKey: 'AIzaSyBkoqR06V9CGc0TKeJTa8FYlid-9tGi2ps',
  authDomain: 'otaro-fca0d.firebaseapp.com',
  databaseURL: 'https://otaro-fca0d.firebaseio.com',
  projectId: 'otaro-fca0d',
  storageBucket: 'otaro-fca0d.appspot.com',
  messagingSenderId: '466557050669',
  appId: '1:466557050669:web:e23a4b8e21e783da437da4',
  measurementId: 'G-GRM60W53YH',
};

export const googleAuth = {
  webClientId:
    '466557050669-dnh0an18a4sutuvhvagvuh0db49455tt.apps.googleusercontent.com',
  webClientSecret: 'GG8tLdWut2DdA8h8A6pk4Q-X',
};

export const server = {
  production: 'https://us-central1-otaro-fca0d.cloudfunctions.net/api',
  devShawn: 'http://192.168.0.147:5000/otaro-fca0d/us-central1/api',
  devDickens: 'http://192.168.0.106:5000',
  local: 'http://localhost:5000/otaro-fca0d/us-central1/api',
};

export const host = {
  local: 'http://localhost:3000',
  devDaryl: 'http://192.168.0.109:3000',
  devShawn: 'http://192.168.0.147:3000',
  production: 'https://otaro.com.au',
  betaProduction: 'https://otaro-beta.web.app',
  upLoadApi: ''
};

export const panoServerUrl = {
  local: 'http://localhost:8080/api/upload',
  production: 'https://api.otaro.com.au/api/upload',
}

export const versionCode = '0.3.1';
